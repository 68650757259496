import React, { useState, useEffect, useContext } from "react";

import filter from "../../assets/icon/filter.svg";
import grid from "../../assets/icon/display-grid-active.svg";
import row from "../../assets/icon/display-row-active.svg";
import artwork from "../../assets/icon/display-artwork-active.svg";
import sortby from "../../assets/icon/sort-by.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import LayoutGrid from "../profile/layoutGrid";
import LayoutExploreGrid from "./layoutExploreGrid";
import AsideFilterExplore from "./asideFilterExplore";
import { Offcanvas } from "react-bootstrap";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintContractABI from "../../abis/SafeMint/abi.json";
import Web3 from "web3";
import CardSkelton from "../shared/cardSkelton";
import { useFeeData } from "wagmi";
import { ClearAllContext } from "./ClearAllContext";
import mintABI from "../../abis/SafeMint/abi.json";
import { useTagContext } from "./TagContext";
import config from "../../config";
import Backend_url from "../../herokuUrlConfig";
import UserCollectedArtwork from "../profile/userCollectedArtwork";
import {
  getArtLastPriceFromFirebase,
  getCollections,
  getUserData,
  getAllUsers,
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { lastIndexOf, update } from "lodash";
import { NFTContext } from "../context/appContext";
import { useLocation } from "react-router-dom";
import UserCollectedRow from "../profile/userCollectedRow";
import axios from "axios";
import _ from "lodash";
import { fetchNFTMetadata } from "../../Services/nft";
import Footer from "../footer";
import { toast } from "react-toastify";
const ExploreArt = ({ tagName, fromSearch, art }) => {

  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const { isChange, search, clearTags, tagsData } = useContext(NFTContext);
  const location = useLocation();
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);
  // const [tagsData, setTagsData] = useState([]);

  const [allUsers, setAllUsers] = useState([]);

  const getUsers = async () => {
    let res = await getAllUsers();
    setAllUsers(res);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (clearTags) {
      setLoading(true);
      setItemsNumber(tagsData?.length);
      if (search == false) {
        setItemsNumber(0);
        setLoading(true);
      }
    } else {
      setLoading(true);

      setItemsNumber(0);
    }
  }, [location, search, clearTags, tagsData]);

  const [activeLayout, setactiveLayout] = useState("grid");
  const { address } = useAccount();
  const [mobileLayout, setMobileLayout] = useState(false);

  // multi filters

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [hide, setHide] = useState(false);

  //  handle mobile filters
  const handleClose = () => setMobileLayout(false);
  const handleShow = () => setMobileLayout(true);

  // layout tabs handles

  const handleActiveLayout = (image) => {
    setactiveLayout(image);
  };

  // let layout handle the icon of currently selected layout
  let layout;
  if (activeLayout == "grid") {
    layout = grid;
  } else if (activeLayout == "row") {
    layout = row;
  } else if (activeLayout == "artwork") {
    layout = artwork;
  }

  // ======================collection sorting filter
  const collectionSortFilter = [
    { value: "Lowest price", label: "Lowest price" },
    { value: "Highest price", label: "Highest price" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  const [activeCollection, setActiveCollection] = useState("Newest");

  const handleIPriceTick = (tick) => {
    setActiveCollection(tick);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  const closeMobileSideFilter = () => setShowSideFilter(false);

  // mobile sort filter
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollectionFilter, setActiveCollectionFilter] =
    useState("Recently Listed");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };

  const handleCollectionTick = (tick) => {
    setActiveCollectionFilter(tick);
    setCollectionMobileFilter(false);
  };
  const [loading, setLoading] = useState(true);
  const { marketplace, erc20 } = config;

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  const [nftDetails, setNftDetails] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  // get listed nfts from blockchain

  //  step no 1
  // get all collections
  const [collections, setCollections] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [nft, setNft] = useState(null);

  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      setCollections(usercollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };

  const getAllListNFTData = async (selectedNetwork, web3) => {
    try {
      if (collections && web3 != null) {
        let MarketplaceAddress;
        if (selectedNetwork === config.networks.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (selectedNetwork === config.networks.flare) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        // alert("in listed");
        const contract = new web3.eth.Contract(
          marketplaceContractABI,
          MarketplaceAddress
        );
        try {
          const data = await contract.methods.getAllListedNfts().call();
          setListedNfts(data);
          return data;
        } catch (error) {
          console.log(error, "error");
        }
      } else {
        setListedNfts([]);
      }
    } catch (error) {
      console.log(error);
    }

    // const accounts = await web3.eth.getAccounts();
  };

  let key = process.env.REACT_APP_CRYPTO_KEY;

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  const [owner, setOwner] = useState(null);



  const getNftMetadata = async (tokens_uri, network) => {
    try {
      setNfts([]);
      const nftMetadata = [];

      if (!clearTags) {
        await Promise.all(
          tokens_uri.map(async (token) => {
            const uri = token.uri; // Extract URI from the current token
            try {
              const json = await fetchNFTMetadata(uri);

              const existingIndex = nftMetadata.findIndex(
                (item) => item.uri === uri
              );
              if (existingIndex === -1 && json) {
                nftMetadata.push({
                  data: token,
                  metadata: {
                    uri: uri,
                    data: json.data,
                  },
                });
              }
            } catch (error) {
              console.log(`Failed to fetch metadata from ${uri}:`, error);
            }
          })
        );
      }

      // Skip processing nftMetadata if tagsData is present and not empty
      if (tagsData?.length > 0 && search) {
        await Promise.all(
          tagsData.map(async (e) => {
            let lastPrice = await getArtLastPriceFromFirebase(e.data.uri);
            if (lastPrice !== null) {
              e.metadata.data.lastPrice = lastPrice;
            } else {
              console.log(`No last price found for artId`);
            }
          })
        );
        setItemsNumber(tagsData?.length);

        setLoading(false);
        setNft(tagsData);
        setNftData(tagsData);
      } else {
        await Promise.all(
          nftMetadata.map(async (e) => {

            let lastPrice = await getArtLastPriceFromFirebase(e.data.uri);
            if (lastPrice !== null) {
              e.metadata.data.lastPrice = lastPrice;

            } else {
              console.log(`No last price found for artId`);
            }
          })
        );

        setItemsNumber(nftMetadata?.length);
        setLoading(false);
        setNft(nftMetadata);
        setNftData(nftMetadata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // step no 2
  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork, id) => {
    try {

      let web;
      if (selectedNetwork == "Flare Mainnet") {
        web = new Web3(config.rpc.flr);
      } else {
        web = new Web3(config.rpc.songbird);
      }


      let nftListDetails = [];
      let nftArray = [];

      if (web) {
        let nftData = [];
        try {
          const contract = new web.eth.Contract(mintABI, contractAddress);
          nftData = await contract.methods.getTokenIdsByCollection(id).call();
        } catch (error) {
          console.log(error, "errror in fetching datra nft data arrrya");
        }

        let listedNfts = await getAllListNFTData(selectedNetwork, web);
        nftData?.map((item) => {
          let isSaleListed = false;
          let isOfferListed = false;
          let saleCountIndex = 0; // Initialize saleCountIndex
          let offerCountIndex = 0; // Initialize offerCountIndex

          // Check for sale listings
          listedNfts[0]?.forEach((nft) => {
            if (nft?.uriData === item?.uri) {
              isSaleListed = true;

              saleCountIndex = nft?.listCount; // Get listCount from sale listing
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isSaleListed: true,
                saleCountIndex, // Add saleCountIndex to the object
              });
            }
          });

          // Check for offer listings
          listedNfts[1]?.forEach((nft) => {
            if (nft?.uriData === item?.uri) {
              isOfferListed = true;
              offerCountIndex = nft?.listCount; // Get listCount from offer listing
              const existingIndex = nftListDetails.findIndex(
                (nftDetail) => nftDetail.uri === item.uri
              );

              if (existingIndex !== -1) {
                nftListDetails[existingIndex] = {
                  ...nftListDetails[existingIndex],
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                };
              } else {
                nftListDetails.push({
                  ...nft,
                  ...item,
                  isListed: true,
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                });
              }
            }
          });

          // Ensure both flags and indices are set correctly for NFTs listed in both categories
          if (isSaleListed || isOfferListed) {
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );
            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isListed: true,
                isSaleListed: isSaleListed,
                isOfferListed: isOfferListed,
                saleCountIndex: isSaleListed
                  ? saleCountIndex
                  : nftListDetails[existingIndex].listCount,
                offerCountIndex: isOfferListed
                  ? offerCountIndex
                  : nftListDetails[existingIndex].listCount,
              };
            }
          }
        });
      }
      return nftListDetails;
    } catch (error) {
      console.log(error);
    }
  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {
    try {
      let contractDataArray = [];

      for (const item of collections) {
        let data = await fetchDataForContract(
          item?.data.contractAddress,
          item?.data.selectedNetwork,
          item?.documentId
        );
        if (data?.length > 0) {
          contractDataArray.push(data);
        }
      }

      // Flatten the contractDataArray into a single array
      const flattenedTokens = contractDataArray.flat();

      // Pass the flattened array to getNftMetadata
      getNftMetadata(flattenedTokens);
      if (collections?.length === 0) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    } else {
      setLoading(false);
    }
  }, [collections, tagsData, search, clearTags]);



  useEffect(() => {
    getUserCollections();
  }, [address, tagsData, search]);

  const [nftData, setNftData] = useState([]);
  const [offerNftData, setOfferNftData] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(0);
  useEffect(() => {
    // Get the current time once
    const now = new Date();

    // Map through the nft array and calculate early access
    const updatedNftFilteredDetails = nft?.map((item) => {

      // Check if the item is sale-listed and extract the list time
      const isSaleListed = item?.data?.isSaleListed;
      const listTime = item?.data?.listedData?.listTime;



      // Default early access to false if the item is not sale-listed
      if (isSaleListed) {


        const sameOwner = item.data.newOwner === item.data.listedData.artist;

        // Convert listTime (Unix timestamp) to a Date object if available
        const createdTime = listTime ? new Date(listTime * 1000) : null;

        // If createdTime is invalid, return early access as false
        if (!createdTime) {
          console.error("Invalid mintTime:", item);
          return { ...item, earlyAccess: false };
        }

        // Calculate the time difference
        const timeElapsed = now - createdTime;
        const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours


        // Determine if the NFT is still in the early access period (less than 24 hours)
        const earlyAccess = hoursElapsed < 24 && sameOwner;


        return {
          ...item,
          earlyAccess, // Add earlyAccess property
        };
      } else {
        return { ...item, earlyAccess: false };
      }
    });

    // filter for blacklisted users


  }, [nft]);

  const handleSelectedFilterChange = (newFilter) => {
    setLoading(true);

    let filteredData = [];

    if (nft?.length > 0) {
      const now = Date.now();

      // Update NFT details with `earlyAccess` property
      const updatedNftFilteredDetails = nft.map((item) => {
        const isSaleListed = item?.data?.isSaleListed;
        const mintTime = item?.data?.listedData?.listTime;

        if (!isSaleListed) {
          return { ...item, earlyAccess: false };
        }

        const createdTime = mintTime ? new Date(mintTime * 1000) : null;

        if (!createdTime) {
          console.error("Invalid mintTime:", item);
          return { ...item, earlyAccess: false };
        }

        const hoursElapsed = Math.floor((now - createdTime) / (1000 * 60 * 60));
        const earlyAccess =
          hoursElapsed < 24 &&
          item.data.newOwner === item.data.listedData.artist;

        return {
          ...item,
          earlyAccess,
        };
      });

      // Conversion rates for blockchain currencies
      const conversionRates = {
        coston: sgb, // Conversion rate for Songbird
        coston2: flr, // Conversion rate for Flare
      };

      // Apply price filter logic
      const applyPriceFilter = (item) => {
        let priceFilter = true;

        if (newFilter.selectPrice.min || newFilter.selectPrice.max) {
          let priceInEth = parseFloat(item.data.listedData.price) / 1e18;

          if (newFilter.priceCurrency === "USD") {
            const blockchain =
              item.metadata.data.selectedBlockchain?.toLowerCase();
            const conversionRate =
              blockchain === "flare mainnet"
                ? conversionRates["coston2"]
                : conversionRates["coston"];
            priceInEth *= conversionRate;
          } else if (
            (newFilter.priceCurrency === "SGB" &&
              item.metadata.data.selectedBlockchain?.toLowerCase() !==
              "songbird mainnet") ||
            (newFilter.priceCurrency === "FLR" &&
              item.metadata.data.selectedBlockchain?.toLowerCase() !==
              "flare mainnet")
          ) {
            priceFilter = false;
          }

          priceFilter =
            priceFilter &&
            (newFilter.selectPrice.min === "" ||
              priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" ||
              priceInEth <= parseFloat(newFilter.selectPrice.max));
        }

        return priceFilter;
      };

      // Common logic for filtering NFTs
      const processFilteredData = (data, filterType) => {
        data.forEach((item) => {
          const isCategorySelected = Object.values(
            newFilter.selectCategories
          ).some(Boolean);
          const selectedCurrency = Object.values(newFilter.selectCurrency).some(
            Boolean
          );

          let categoriesFilter = true;
          let currencyFilter = true;

          if (isCategorySelected) {
            categoriesFilter = Object.keys(newFilter.selectCategories).every(
              (category) =>
                newFilter.selectCategories[category]
                  ? item.metadata.data.selectedTags.includes(category)
                  : true
            );
          }

          if (selectedCurrency) {
            const blockchain =
              item.metadata.data.selectedBlockchain?.toLowerCase();
            currencyFilter =
              newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr &&
                blockchain === "flare mainnet") ||
              (newFilter.selectCurrency.sgb &&
                blockchain === "songbird mainnet");
          }

          if (categoriesFilter && currencyFilter && applyPriceFilter(item)) {
            filteredData.push(item);
          }
        });
      };

      // Filter based on `buyNow` status
      if (newFilter.selectStatus.buyNow) {
        const buyNowNfts = updatedNftFilteredDetails.filter(
          (item) => item.data.isSaleListed
        );
        processFilteredData(buyNowNfts, "buyNow");
      }

      // Filter based on `onOffer` or `All` status
      if (newFilter.selectStatus.onOffer || newFilter.selectStatus.All) {
        const offerNftData = updatedNftFilteredDetails.filter(
          (item) => item.data.isOfferListed
        );
        processFilteredData(offerNftData, "onOffer");
      }

      // Filter based on `earlyAccess` status
      if (newFilter.selectStatus.earlyAccess) {
        const earlyAccessData = updatedNftFilteredDetails.filter(
          (item) => item.earlyAccess
        );
        processFilteredData(earlyAccessData, "earlyAccess");
      }

      setItemsNumber(filteredData.length);
      setNftFilteredDetails(filteredData);
    }

    setLoading(false);
  };

  useEffect(() => {

    if (nft) {
      if (activeCollection === "Highest price") {
        const nftsWithPrice = [...nft].filter(
          (a) => a?.data?.listedData?.price !== undefined
        );
        const nftsWithoutPrice = [...nft].filter(
          (a) => a?.data?.listedData?.price === undefined
        );

        const sortByHighestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          return bPrice - aPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByHighestPrice, ...nftsWithoutPrice];

        setNftFilteredDetails(sortedNftList);
      }

      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const nftsWithPrice = [...nft].filter(
          (a) => a?.data?.listedData?.price !== undefined
        );
        const nftsWithoutPrice = [...nft].filter(
          (a) => a?.data?.listedData?.price === undefined
        );

        const sortByLowestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          return aPrice - bPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByLowestPrice, ...nftsWithoutPrice];

        setNftFilteredDetails(sortedNftList);
      }

      // Getting the first 5 recently listed items
      else if (activeCollection === "Newest") {
        const recentlyAdded = [...nft].sort((a, b) => {
          return b?.data
            ? b?.data?.mintTime -
            (a?.data && a?.data.mintTime ? a?.data.mintTime : 0)
            : 0;
        });

        setNftFilteredDetails(recentlyAdded);
      } else if (activeCollection === "Oldest") {
        const oldestAdded = [...nft].sort((a, b) => {
          return a?.data
            ? a?.data?.mintTime -
            (b?.data && b?.data.mintTime ? b?.data.mintTime : 0)
            : 0;
        });



        setNftFilteredDetails(oldestAdded);
      }
    }
    // setLoading(false);
  }, [nft, activeCollection]);


  const clearFilter = () => {
    setClearAll(true);
  };





  const [whitelistedData, setWhitelistedData] = useState([]);

  useEffect(() => {

    const filteredNfts = nftFilteredDetails?.filter((nft) => {



      // Find the user with the matching ID
      const owner = allUsers.find((user) => user.id === nft.data.newOwner);

      // Check if the owner is found and is not blacklisted
      return owner && owner?.isBlackListed == false;
    });

    setItemsNumber(filteredNfts?.length);



    // Update the state with the new array
    setWhitelistedData(filteredNfts);

  }, [nftFilteredDetails])
  return (
    <div>
      {search && tagsData?.length == 0 && location?.state?.tag ? (
        <>
          <div className="no-content mt-5 pt-5">
            <p className="body-large">
              No Artwork Found for <span>{location?.state?.tag}</span>
            </p>
          </div>
        </>
      ) : (
        <div>
          <div className="collection-filter">
            <div className="left">
              <div
                className="show-filter cursor-pointer "
                onClick={showMobileSideFilter}
              >
                <span>
                  <img src={filter} alt="filter" id="filter" />
                </span>
                <label
                  htmlFor="filter"
                  className="medium text-black cursor-pointer ms-1 hide-on-mobile"
                >
                  {hide ? "Hide Filters" : "Show Filters"}
                </label>
              </div>
              <div className="t-items">
                {hide && isChange ? (
                  <label onClick={clearFilter} className="medium ms-4">
                    Clear All
                  </label>
                ) : (
                  <label className="medium ms-4">{itemsNumber} Items</label>
                )}
              </div>
            </div>

            <div className="right">
              {/* Sorting filter dropdown desktop*/}
              <div className="collection-grid hide-on-mobile">
                <img
                  src={grid}
                  alt="grid"
                  className={
                    activeLayout === "grid" ? "active opacity-100 " : ""
                  }
                  onClick={() => handleActiveLayout("grid")}
                />
                <div className="divider"></div>
                <img
                  src={row}
                  alt="grid"
                  className={activeLayout === "row" ? "active opacity-100" : ""}
                  onClick={() => handleActiveLayout("row")}
                />
                <div className="divider"></div>
                <img
                  src={artwork}
                  alt="grid"
                  className={
                    activeLayout === "artwork" ? "active opacity-100" : ""
                  }
                  onClick={() => handleActiveLayout("artwork")}
                />
              </div>

              {/* layout tabs for  mobile menu */}
              <div className="collection-grid-sm">
                <span
                  className="hide-on-desktop d-flex align-items-center me-1"
                  onClick={handleShow}
                >
                  <img
                    src={layout}
                    alt="layout"
                    className={activeLayout === "grid" ? "active " : ""}
                  />
                </span>
              </div>

              <div className="recent-collection filter dropdown hide-on-mobile ">
                <p
                  className="body-medium dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
                <ul className="dropdown-menu">
                  {collectionSortFilter.map((option) => {
                    return (
                      <li
                        className="dropdown-item"
                        key={option.value}
                        onClick={() => handleIPriceTick(option.value)}
                      >
                        <img
                          src={tick}
                          alt="tick"
                          className={`${activeCollection === option.value
                            ? "active opacity-100"
                            : "opacity-0"
                            }`}
                        />
                        {option.label}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Sorting filter dropdown Mobile*/}
              <div className="recent-collection filter hide-on-desktop ">
                <p className="body-medium " onClick={showMobileSortFilter}>
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
              </div>
            </div>
          </div>
          {activeLayout == "grid" ? (
            <LayoutExploreGrid
              flag={Flag}
              listedNft={whitelistedData}
              onSelectedFilterChange={handleSelectedFilterChange}
              loading={loading}
              setLoading={setLoading}
              setItemsNumber={setItemsNumber}
              tagName={tagName}
            />
          ) : activeLayout == "row" ? (
            <UserCollectedRow
              id={null}
              flag={Flag}
              listedNft={whitelistedData}
              onSelectedFilterChange={handleSelectedFilterChange}
              owner={owner}
              address={address}
              loading={loading}
              type="explore"
            />
          ) : activeLayout == "artwork" ? (
            <UserCollectedArtwork
              id={null}
              flag={Flag}
              listedNft={whitelistedData}
              onSelectedFilterChange={handleSelectedFilterChange}
              owner={owner}
              address={address}
              loading={loading}
            />
          ) : (
            <></>
          )}
        </div>
      )}

      <Offcanvas
        show={showSideFilter}
        onHide={closeMobileSideFilter}
        placement="bottom"
        className="sub-menu-offcanvas collection-multi-filter aside-filter-offcanvas"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label htmlFor="">
              {nftFilteredDetails?.length ? nftFilteredDetails?.length : "0"}{" "}
              Items
            </label>
            <label className="text-black multi-filter-head">Filters</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={closeMobileSideFilter}
              />
            </div>
          </div>

          {/* <AsideFilterExplore /> */}
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
            clear={clearAll}
          />
        </div>
      </Offcanvas>

      {/* Collection Sorting Filter mobile menu */}
      <Offcanvas
        show={collectionMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {collectionSortFilter.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeCollection === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => {
                  handleIPriceTick(option.value);
                  hideMobileSortFilter();
                }}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeCollection === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>

      <Offcanvas
        show={mobileLayout}
        onHide={handleClose}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label className="text-black">layout</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={handleClose}
              />
            </div>
          </div>

          <div className="share">
            <label
              className=" h-64 text-black"
              onClick={() => {
                handleActiveLayout("grid");
                setMobileLayout(false);
              }}
            >
              <div>
                <img src={grid} alt="grid" className="me-3 " />
                Grid Layout
              </div>

              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "grid" ? "active opacity-100" : "opacity-0"
                }
              />
            </label>
            <label
              className=" h-64 text-black"
              onClick={() => {
                handleActiveLayout("row");
                setMobileLayout(false);
              }}
            >
              <div className="cursor-pointer">
                <img src={row} alt="row" className="me-3 " />
                Row Layout
              </div>
              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "row" ? "active opacity-100" : "opacity-0"
                }
              />
            </label>
            <label
              className=" h-64 text-black"
              onClick={() => {
                handleActiveLayout("artwork");
                setMobileLayout(false);
              }}
            >
              <div className="cursor-pointer">
                <img src={artwork} alt="artwork" className="me-3 " />
                Artwork Layout
              </div>

              <img
                src={tick}
                alt="tick"
                className={
                  activeLayout === "artwork"
                    ? "active opacity-100"
                    : "opacity-0"
                }
              />
            </label>
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default ExploreArt;
