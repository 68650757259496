import { initializeApp } from "firebase/app";
import { getDownloadURL, ref, uploadBytes, getStorage } from "firebase/storage";
import { toast } from "react-toastify";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAuth,
  sendEmailVerification,
} from "firebase/auth";
import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  deleteField,
  doc,
  getFirestore,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { deleteObject } from "firebase/storage";
import firebaseConfig from "../firebaseConfig";


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const secondaryAuth = getAuth(app);
const storage = getStorage(app);
const firestoredb = getFirestore(app);

// upload image
export const uploadImg = async (image, address) => {
  try {
    const imageRef = ref(storage, Date.now() + "image");
    const snapshot = await uploadBytes(imageRef, image);
    const imageUrl = await getDownloadURL(imageRef);

    await setDoc(
      doc(firestoredb, "Users", address),
      {
        image: imageUrl,
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// delete image

export const deleteImg = async (imageUrl, address) => {
  try {

    const imageName = imageUrl.substring(imageUrl?.lastIndexOf("/") + 1);
    const imageRef = ref(storage, imageUrl);
    await deleteObject(imageRef);
    await updateDoc(doc(firestoredb, "Users", address), {
      image: deleteField(),
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get admin users

export const getAdminUser = async () => {
  try {
    const docRef = doc(firestoredb, "Users", "adminUser");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

// Function to set the username in the profile section it will first check if the username exists
// it will through error
export const setUsername = async (username, address) => {
  try {
    // Check if the username already exists for any other address
    const usernameDocRef = doc(firestoredb, "UserNames", username);
    const usernameDocSnap = await getDoc(usernameDocRef);

    if (
      usernameDocSnap.exists() &&
      usernameDocSnap.data().address !== address
    ) {
      return false;
    } else {
      // Update the username for the current address
      await setDoc(
        doc(firestoredb, "Users", address),
        {
          userName: username,
        },
        { merge: true }
      );

      // Set the username document
      await setDoc(usernameDocRef, {
        username: username,
        address: address,
      });

      return "true";
    }
  } catch (err) {
    console.error(err);
    return err.message;
  }
};

// email to new Letter function

export const emailToNewsLetter = async (email) => {
  try {
    await setDoc(doc(firestoredb, "NewsLetter", email), {
      email: email,
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// Add or Update Top banner Text

export const addOrUpdateTopBannerText = async (text, enable) => {
  try {
    await setDoc(doc(firestoredb, "TopBannerText", "text"), {
      text: text,
      enable: enable,
    });
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get Top banner Text

export const getTopBannerText = async () => {
  try {
    const docRef = doc(firestoredb, "TopBannerText", "text");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

// function to get Applied artists

export const getAppliedArtistsFirebase = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "artistsDetails")
    );
    const artists = [];
    // document id need to be added in the array

    querySnapshot.forEach((doc) => {
      artists.push({ id: doc.id, ...doc.data() });
    });

    // querySnapshot.forEach((doc) => {
    //   artists.push(doc.data());
    // });
    return artists;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// function to update Applied artists
export const updateAppliedArtistsFirebase = async (
  address,
  approved,
  isBlacklisted
) => {
  try {
    await setDoc(
      doc(firestoredb, "artistsDetails", address),
      {
        approved: approved,
        isBlacklisted: isBlacklisted,
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get all artists from firebase and return last added artists

export const getNewArtists = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "artistsDetails")
    );
    const artists = [];
    querySnapshot.forEach((doc) => {
      artists.push({
        id: doc.id, // Document ID (name)
        ...doc.data(), // Get all the other fields
      });
    });
    artists.sort((a, b) => b.creationTime - a.creationTime); // Sort by creationTime in descending order
    return artists;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// save and apply data to firebase

export const saveApplyDatatofirebase = async (formData, address) => {
  const docRef = doc(firestoredb, "artistsDetails", address);
  try {
    const result = await setDoc(
      docRef,
      {
        name: formData.name,
        portfolio: formData.portfolio,
        instagram: formData.instagram,
        twitter: formData.twitter,
        allLinks: formData.allLinks,
        email: formData.email,
        videoLink: formData.videoLink,
        selectionOfArtwork: formData.selectionOfArtwork,
        storyBehind: formData.storyBehind,
        howDidYouHear: formData.howDidYouHear,
        creationTime: Date.now(),
        approved: false,
        isBlacklisted: false,
      },
      { merge: true }
    );
    if (result == undefined) {
      toast("Successfully form submission");
    }
  } catch (error) {
    toast.error("Something went wrong while applying", {
      toastId: "applyError",
    });
  }
};

// Retrieve user profile data to use in the edit profile section
export const getUserData = async (address) => {
  if (address) {
    try {
      const docRef = doc(firestoredb, "Users", address);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }
};

// Login function used for authentication
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userInfo = await signInWithEmailAndPassword(auth, email, password);
    localStorage.isSigninSuccess = true;
    return { success: true, userInfo };
  } catch (e) {
    toast.error("User and Password not correct", { toastId: "loginError" });
    return { success: false, error: e };
  }
};

// Function for sending email confirmation to the user
const registerWithEmailAndPassword = async (email, password, date) => {
  try {
    const userInfo = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userInfo.user;

    await sendEmailVerification(userInfo.user);

    await setDoc(doc(firestoredb, "Users", user.uid), {
      uid: user.uid,
      email: email,
      isApproved: true,
      isAdmin: false,
      firstName: "",
      lastName: "",
      dateOfBirth: date,
      phoneNumber: "",
      bindedWallet: "",
    });
    localStorage.isSignupSuccess = true;
    let userData = {
      email: user.email,
      name: user.displayName,
      uid: user.uid,
    };
    const jsonString = JSON.stringify(userData);
    localStorage.setItem("tuxnftuser", jsonString);
    toast("Signup success");

    await signOut(auth);
    return true;
  } catch (error) {
    console.log(error);
    if (error.code === "auth/email-already-in-use") {
      toast("This Email is already Registered");
    }
    return false;
  }
};

// get All user data from firebase
export const getAllUsers = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "Users"));
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        ...doc.data(), // Get all the other fields
      });
    });
    return users;
  } catch (err) {
    console.error(err);
    return null;
  }
};

// save featured User and image to firebase

export const saveFeaturedUser = async (data) => {
  try {
    const { artistAddress, featuredImage } = data;

    console.log(artistAddress, "artistAddress", featuredImage, "feature image");


    if (artistAddress == null || featuredImage == null) {
      toast.error("Please select artist name and image to add feature image.");
      return;
    }
    // Check if the featuredImage is not empty
    if (featuredImage !== "" && featuredImage !== null) {
      const imageRef = ref(storage, Date.now() + "image");
      const snapshot = await uploadBytes(imageRef, featuredImage);
      const imageUrl = await getDownloadURL(imageRef);

      // Prepare the data to be saved to Firestore
      const dataToSave = {
        timestamp: serverTimestamp(), // Add the server timestamp
        image: imageUrl, // This will be null if no image is provided,
        artistAddress: artistAddress
      };
      console.log(dataToSave, "data to save");
      // Save the document in the "featuredUsers" collection
      await setDoc(
        doc(firestoredb, "featuredUsers", artistAddress),
        dataToSave,
        { merge: true }
      );
    } else {
      // if featuredImage is empty, check if there is already an image for the artistAddress
      const artistDoc = await getDoc(doc(firestoredb, "featuredUsers", artistAddress));
      if (artistDoc.exists() && artistDoc.data().image) {
        const imageRef = ref(storage, artistDoc.data().image);
        await deleteObject(imageRef);
        await setDoc(
          doc(firestoredb, "featuredUsers", artistAddress),
          {
            image: null
          },
          { merge: true }
        );
      }
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};


// get featured user from firebase and return last added user

export const getFeaturedUser = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "featuredUsers")
    );
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        image: doc.data().image, // Get the 'image' field
        timestamp: doc.data().timestamp, // Get the 'timestamp' field
      });
    });
    users.sort((a, b) => b.timestamp - a.timestamp); // Sort by timestamp in descending order
    return users.length > 0 ? users[0] : null; // Return the first document with the latest timestamp
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const SaveSpotlight = async (data) => {
  try {
    const {
      spotActive,
      spotAddress,
      spotUsername,
      spotInstagram,
      spotDiscord,
      spotTwitter,
      spotWebsite,
      mainTitle,
      spotFeaturedImage1,
      spotFeaturedName1,
      spotFeaturedImage2,
      spotFeaturedName2,
      spotFeaturedImage3,
      spotFeaturedLink1,
      spotFeaturedLink2,
      spotFeaturedLink3,
      spotFeaturedName3,

      spotBio,
      spotHeader1,
      spotSection1image,
      spotSection1,
      spotHeader2,
      spotSection2,
      spotSection2image,
      spotHeader3,
      spotSection3,
      spotSection3image1,
      spotSection3image2,
    } = data;

    // Initialize image URLs
    let imageUrl1 = spotFeaturedImage1 || null;
    let imageUrl2 = spotFeaturedImage2 || null;
    let imageUrl3 = spotFeaturedImage3 || null;
    let imageUrl4 = spotSection1image || null;
    let imageUrl5 = spotSection2image || null;
    let imageUrl6 = spotSection3image1 || null;
    let imageUrl7 = spotSection3image2 || null;

    // Upload images only if they are non-empty and objects (File objects)
    if (spotFeaturedImage1 && typeof spotFeaturedImage1 === "object") {
      const imageRef1 = ref(storage, Date.now() + "FeaturedImage1");
      await uploadBytes(imageRef1, spotFeaturedImage1);
      imageUrl1 = await getDownloadURL(imageRef1);
    }

    if (spotFeaturedImage2 && typeof spotFeaturedImage2 === "object") {
      const imageRef2 = ref(storage, Date.now() + "FeaturedImage2");
      await uploadBytes(imageRef2, spotFeaturedImage2);
      imageUrl2 = await getDownloadURL(imageRef2);
    }

    if (spotFeaturedImage3 && typeof spotFeaturedImage3 === "object") {
      const imageRef3 = ref(storage, Date.now() + "FeaturedImage3");
      await uploadBytes(imageRef3, spotFeaturedImage3);
      imageUrl3 = await getDownloadURL(imageRef3);
    }

    if (spotSection1image && typeof spotSection1image === "object") {
      const imageRef4 = ref(storage, Date.now() + "Section1image");
      await uploadBytes(imageRef4, spotSection1image);
      imageUrl4 = await getDownloadURL(imageRef4);
    }

    if (spotSection2image && typeof spotSection2image === "object") {
      const imageRef5 = ref(storage, Date.now() + "Section2image");
      await uploadBytes(imageRef5, spotSection2image);
      imageUrl5 = await getDownloadURL(imageRef5);
    }

    if (spotSection3image1 && typeof spotSection3image1 === "object") {
      const imageRef6 = ref(storage, Date.now() + "Section3image1");
      await uploadBytes(imageRef6, spotSection3image1);
      imageUrl6 = await getDownloadURL(imageRef6);
    }

    if (spotSection3image2 && typeof spotSection3image2 === "object") {
      const imageRef7 = ref(storage, Date.now() + "Section3image2");
      await uploadBytes(imageRef7, spotSection3image2);
      imageUrl7 = await getDownloadURL(imageRef7);
    }

    // Remove all existing documents in spotlightUsers collection
    const querySnapshot = await getDocs(
      collection(firestoredb, "spotlightUsers")
    );
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });

    // Set the new document in the "spotlightUsers" collection
    await setDoc(
      doc(firestoredb, "spotlightUsers", spotAddress),
      {
        spotActive: spotActive,
        spotAddress: spotAddress,
        spotUsername: spotUsername,
        spotInstagram: spotInstagram,
        spotDiscord: spotDiscord,
        spotTwitter: spotTwitter,
        spotWebsite: spotWebsite,
        mainTitle: mainTitle,
        spotFeaturedImage1: imageUrl1,
        spotFeaturedName1: spotFeaturedName1,
        spotFeaturedImage2: imageUrl2,
        spotFeaturedName2: spotFeaturedName2,
        spotFeaturedImage3: imageUrl3,
        spotFeaturedName3: spotFeaturedName3,
        spotFeaturedLink1: spotFeaturedLink1,
        spotFeaturedLink2: spotFeaturedLink2,
        spotFeaturedLink3: spotFeaturedLink3,
        spotBio: spotBio,
        spotHeader1: spotHeader1,
        spotSection1image: imageUrl4,
        spotSection1: spotSection1,
        spotHeader2: spotHeader2,
        spotSection2: spotSection2,
        spotSection2image: imageUrl5,
        spotHeader3: spotHeader3,
        spotSection3: spotSection3,
        spotSection3image1: imageUrl6,
        spotSection3image2: imageUrl7,
      },
      { merge: true }
    );

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};


// get spotlight user from firebase and return last added user

export const getSpotlightUser = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "spotlightUsers")
    );
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (err) {
    console.error(err);
    return null;
  }
};

// handle All activities of collection

// export const handleCollectionHistory = async (collectionId, data) => {
//   try {
//     const { action, user, artworkUri, from, to, price, tokenId } = data;
//     console.log("Collection history data:", data);
//     console.log("Collection history action:", action);
//     console.log("Collection history user:", user);
//     console.log("Collection history artworkUri:", artworkUri);
//     console.log("Collection history from:", from);
//     console.log("Collection history to:", to);
//     console.log("Collection history price:", price);
//     const timestamp = serverTimestamp();
//     console.log("Collection history timestamp:", timestamp);

//     const collectionHistoryRef = doc(
//       firestoredb,
//       "collectionHistory",
//       collectionId
//     );
//     const collectionHistoryDoc = await getDoc(collectionHistoryRef);
//     if (collectionHistoryDoc.exists()) {
//       const collectionHistoryData = collectionHistoryDoc.data();
//       const newCollectionHistoryData = {
//         ...collectionHistoryData,
//         [tokenId]: {
//           action: action,
//           user: user,
//           artworkUri: artworkUri,
//           from: from,
//           to: to,
//           price: price,
//           timestamp: timestamp,
//         },
//       };
//       await setDoc(collectionHistoryRef, newCollectionHistoryData);
//     } else {
//       const newCollectionHistoryData = {
//         [tokenId]: {
//           action: action,
//           user: user,
//           artworkUri: artworkUri,
//           from: from,
//           to: to,
//           price: price,
//           timestamp: timestamp,
//         },
//       };
//       await setDoc(collectionHistoryRef, newCollectionHistoryData);
//     }
//     console.log("Collection history updated successfully");
//     return true;
//   } catch (error) {
//     console.error(error);
//     return false;
//   }
// };

// export const handleCollectionHistory = async (collectionId, data) => {
//   try {
//     const { action, user, artworkUri, from, to, price, tokenId } = data;

//     // Reference to the Firestore collection path
//     const collectionHistoryRef = doc(
//       firestoredb,
//       "CollectionHistory",
//       collectionId
//     );

//     // Check if the document already exists
//     const docSnap = await getDoc(collectionHistoryRef);

//     if (docSnap.exists()) {
//     //   // if document exists, keep the existing data and add the new data

//     } else {
//     // If the document doesn't exist, create it
//     await setDoc(collectionHistoryRef, {
//       tokenId,
//       action,
//       user,
//       artworkUri,
//       from,
//       to,
//       price,
//       timestamp: serverTimestamp(),
//     });
//     }

//     console.log("Data saved successfully");
//   } catch (error) {
//     console.error("Error saving data:", error);
//   }
// };
export const handleCollectionHistory = async (collectionId, data) => {
  try {
    // Validate input data
    if (!collectionId || !data) {
      throw new Error("Invalid input. Please provide collectionId and data.");
    }

    const { action, user = null, artworkUri, from, to, price = null, tokenId, uri = null, transactionHash } = data;

    // Reference to the Firestore collection path and random document ID
    const randomDocId = Math.random().toString(36).substring(2, 8);
    const collectionHistoryRef = doc(
      firestoredb,
      "CollectionHistory",
      `${collectionId}_${randomDocId}`
    );

    const docSnap = await getDoc(collectionHistoryRef);

    if (docSnap.exists()) {
      // Update existing document
      await setDoc(
        collectionHistoryRef,
        {
          collectionId,
          tokenId,
          action,
          user,
          artworkUri,
          from,
          to,
          price,
          timestamp: serverTimestamp(),
          uri,
          transactionHash
        },
        { merge: true }
      );
    } else {
      // Create a new document
      await setDoc(collectionHistoryRef, {
        collectionId,
        tokenId,
        action,
        user,
        artworkUri,
        from,
        to,
        price,
        uri,
        timestamp: serverTimestamp(),
        transactionHash
      });
    }


    return { success: true }; // Return a success object or value
  } catch (error) {
    console.error("Error saving data:", error);
    throw error; // Rethrow the error to propagate it
  }
};

// get collection history from firebase

export const getCollectionHistoryByCollectionId = async (collectionId) => {
  try {
    // Validate input data
    if (!collectionId) {
      console.error("Invalid input. Please provide collectionId.");
      return;
    }

    const collectionHistoryQuery = query(
      collection(firestoredb, "CollectionHistory"),
      where("collectionId", "==", collectionId)
    );

    const querySnapshot = await getDocs(collectionHistoryQuery);

    const collectionHistory = [];

    querySnapshot.forEach((doc) => {
      collectionHistory.push(doc.data());
    });

    return collectionHistory;
  } catch (error) {
    console.error("Error getting collection history:", error);
  }
};

export const getCollectionHistory = async () => {
  try {
    // Create a query to get the first 10 documents from the CollectionHistory collection
    const collectionHistoryQuery = query(
      collection(firestoredb, "CollectionHistory")
    );

    const querySnapshot = await getDocs(collectionHistoryQuery);

    const collectionHistory = [];

    querySnapshot.forEach((doc) => {
      collectionHistory.push(doc.data());
    });

    return collectionHistory;
  } catch (error) {
    console.error("Error getting collection history:", error);
  }
};
// getting setting from firebase
export const getSettingFirebase = (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const querySnapshot = await getDocs(collection(firestoredb, "Settings"));

      const docRef = doc(firestoredb, "Settings", address);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        resolve(docSnap.data());
      } else {
        resolve(false)
        // reject(new Error("Document not found"));
      }
    } catch (err) {
      console.error(err);
      reject(err); // Reject with the actual error
    }
  });
};

// save settings to firebase

export const saveSettingToFirebase = async (data, address) => {
  return new Promise(async (resolve, reject) => {
    try {
      await setDoc(doc(firestoredb, "Settings", address), {
        data: data,
      });

      resolve({ success: true });
    } catch (error) {
      toast.error("Something went wrong while applying Setting", {
        toastId: "applyError",
      });
      reject({ success: false });
    }
  });
};
export const saveProfileSettingToFirebase = async (privateStatus, address) => {
  return new Promise(async (resolve, reject) => {
    try {
      await setDoc(doc(firestoredb, "ProfileSettings", address), {
        private: privateStatus,
      }).then((res) => { });

      resolve({ success: true });
    } catch (error) {
      toast.error("Something went wrong while saving Profile Setting", {
        toastId: "profileSettingError",
      });
      reject({ success: false });
    }
  });
};
export const getProfileSettingFirebase = async (address) => {
  try {
    const docRef = doc(firestoredb, "ProfileSettings", address);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

// get userdata by username

export const getUserDataByUserName = (username) => {

  return new Promise(async (resolve, reject) => {
    try {
      const usersRef = collection(firestoredb, "Users");
      // Create a query against the collection.
      const q = query(usersRef, where("userName", "==", username));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        // doc.data() is never undefined for query doc snapshots
        resolve({ documentId: doc.id, s: doc.data() });
      });
    } catch (error) {
      reject(null);
    }
  });
};
export const getImageByUsername = (username) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usersRef = collection(firestoredb, "Users");
      // Create a query against the collection.
      const q = query(usersRef, where("userName", "==", username));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // Assuming there's an 'image' field in the user document
        const imageData = doc.data().image;
        resolve(imageData);
      });
    } catch (error) {
      reject(null);
    }
  });
};

const uploadImageStorageAndGetLink = async (image) => {
  const imageRef = ref(storage, Date.now() + "image");
  //upload image to firestore storage
  const snapshot = await uploadBytes(imageRef, image);
  //get url of the
  const imageUrl = await getDownloadURL(imageRef);
  return imageUrl;
};

// add collection in firebase

export const saveCollection = async (
  name,
  symbol,

  description,

  address,

  selectedNetwork,
  contractAddress,
  hash
) => {
  // alert("here");
  try {
    const firestoredb = getFirestore();
    const storage = getStorage();

    // Save the collection details along with the image URL
    const docRef = doc(collection(firestoredb, "Collections"));

    await setDoc(docRef, {
      name: name,
      symbol: symbol,
      address: address,
      description: description,
      selectedNetwork: selectedNetwork,
      contractAddress: contractAddress,
      transactionHash: hash,
      Approved: false,
      isWhiteList: true,
      isBlackList: false,
      timestamp: serverTimestamp(),
    });

    // Get the document ID
    const docId = docRef.id;

    // Return the document ID along with the response
    return { success: true, docId: docId };
  } catch (error) {
    console.error("Error saving collection:", error);
    toast.error("Something went wrong while saving collection", {
      toastId: "saveCollectionError",
    });
    return { success: false, error: error.message };
  }
};


// get contract address by collection


export const getCollectionIdByContractAddress = async (contractAddress) => {
  try {
    const firestoredb = getFirestore();
    const querySnapshot = await getDocs(
      query(collection(firestoredb, "Collections"), where("contractAddress", "==", contractAddress))
    );

    if (querySnapshot.empty) {
      return null;
    }

    const collectionId = querySnapshot.docs[0].id;

    return collectionId;
  } catch (error) {
    console.error("Error fetching collection ID:", error);
    throw error; // Re-throw the error for handling at a higher level
  }
};

export const getContractAddressByCollectionId = async (collectionId) => {

  try {
    const docRef = doc(firestoredb, "Collections", collectionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.contractAddress;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching contract address:", error);
    throw error; // Re-throw the error for handling at a higher level
  }
};
// update collection in firebase



export const updateCollection = async (
  name,
  symbol,
  address,
  contractAddress,
  documentId,
  approved
) => {
  try {
    await setDoc(doc(firestoredb, "Collections", documentId), {
      name: name,
      symbol: symbol,
      address: address,
      contractAddress: contractAddress,
      Approved: approved,
    }).then((res) => {
      // toast.success("Collection approved successfully");
      return true;
    });
  } catch (error) {
    toast.error("Something went wrong while applying Setting", {
      toastId: "applyError",
    });
    return false;
  }
};

// update collection approved status in firebase

export const updateCollectionApprovedStatus = async (documentId, approved) => {
  try {
    await setDoc(
      doc(firestoredb, "Collections", documentId),
      {
        Approved: approved,
      },
      { merge: true }
    ).then((res) => {
      toast.success("Artwork Approved successfully");
      return true;
    });
  } catch (error) {
    toast.error("Something went wrong while applying Setting", {
      toastId: "applyError",
    });
    return false;
  }
};

// update collection status in firebase

export const updateCollectionStatus = async (
  documentId,
  // isWhiteList,
  isBlackList
) => {
  try {
    await setDoc(
      doc(firestoredb, "Collections", documentId),
      {
        // isWhiteList: isWhiteList,
        isBlackList: isBlackList,
      },
      { merge: true }
    ).then((res) => {
      toast.success("Collections updated successfully");
      return true;
    });
  } catch (error) {
    toast.error("Something went wrong while applying Setting", {
      toastId: "applyError",
    });
    return false;
  }
};

// get collection from firebase

export const getCollections = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let allCollections = [];
      const querySnapshot = await getDocs(
        collection(firestoredb, "Collections")
      );
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        allCollections.push({ documentId: doc.id, data: doc.data() });

        // lastest document on top by timestamp
        allCollections.sort(
          (a, b) => b?.data?.timestamp?.seconds - a?.data?.timestamp?.seconds
        );
      });
      resolve(allCollections);
    } catch (error) {
      reject(null);
    }
  });
};

export const getCollectionsByAddress = async (address) => {
  return new Promise(async (resolve, reject) => {
    try {
      let collectionsByAddress = [];
      const querySnapshot = await getDocs(
        collection(firestoredb, "Collections")
      );
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const collectionData = doc.data();
        if (address && collectionData.address === address) {
          collectionsByAddress.push({
            documentId: doc.id,
            data: collectionData,
          });
        }
      });
      // Sort collections by timestamp
      collectionsByAddress.sort(
        (a, b) => b?.data?.timestamp?.seconds - a?.data?.timestamp?.seconds
      );
      resolve(collectionsByAddress);
    } catch (error) {
      console.error("Error fetching collections by address:", error);
      reject(null);
    }
  });
};

// function to get collection details based on location details.

export const getCollectionDetailsFirebase = (locationDetails) => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = doc(firestoredb, "Collections", locationDetails);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
      } else {
        // docSnap.data() will be undefined in this case
      }

      resolve(docSnap.data());
    } catch (error) {
      reject(null);
    }
  });
};

// function to upload image

// Save Edit collection to firebase
export const saveEditCollectionDetails = async (
  image,
  description,
  collectionUrl,
  documentId
) => {
  try {
    let logoImageUrl;

    if (image === "") {
      logoImageUrl = null;
    } else {
      logoImageUrl = await uploadImageStorageAndGetLink(image);
    }

    // Now you can use logoImageUrl for further processing

    await setDoc(
      doc(firestoredb, "Collections", documentId),
      {
        image: logoImageUrl,
        description: description,
        collectionUrl: collectionUrl,
        creationTime: Date.now(),
      },
      { merge: true }
    );
    toast.success("Data saved successfully");
  } catch (error) { }
  // toast.error("Error in saving data");
};

// adding favourite to firebase
export const handleFavorite = async (userName, id) => {
  try {
    const favoritesCollection = collection(firestoredb, "favorites");
    const querySnapshot = await getDocs(
      query(favoritesCollection, where("nftId", "==", id))
    );
    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      return false;
    }
    await addDoc(favoritesCollection, {
      userName: userName,
      nftId: id,
    });

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// add artist followers to firebase


export const handleFollow = async (userAddress, artistAddress) => {
  try {
    // Reference to the 'users' collection in Firestore
    const usersCollection = collection(firestoredb, 'followers');
    // Query to find the user document
    const userQuerySnapshot = await getDocs(query(usersCollection, where("address", "==", userAddress)));

    if (userQuerySnapshot.empty) {
      // If user document does not exist, create a new one
      await setDoc(doc(usersCollection, userAddress), {
        address: userAddress,
        following: [artistAddress], // Start following the artist
        followers: [] // Initialize empty followers array
      });
    } else {
      // User document exists, update following array
      const userDocRef = doc(usersCollection, userAddress);
      await updateDoc(userDocRef, {
        following: arrayUnion(artistAddress)
      });
    }

    // Reference to the 'artists' collection in Firestore
    const artistsCollection = collection(firestoredb, 'followers');
    // Query to find the artist document
    const artistQuerySnapshot = await getDocs(query(artistsCollection, where("address", "==", artistAddress)));

    if (artistQuerySnapshot.empty) {
      // If artist document does not exist, create a new one
      await setDoc(doc(artistsCollection, artistAddress), {
        address: artistAddress,
        followers: [userAddress], // Start with the user as a follower
        following: []
      });
    } else {
      // Artist document exists, update followers array
      const artistDocRef = doc(artistsCollection, artistAddress);
      await updateDoc(artistDocRef, {
        followers: arrayUnion(userAddress)
      });
    }

  } catch (error) {
    console.error("Error handling follow:", error);
  }
};
// Function to check if an artist is followed
export const checkIfFollowed = async (userAddress, artistAddress) => {
  if (userAddress) {

    try {
      const followerDocRef = doc(firestoredb, 'followers', userAddress);
      const followerDocSnap = await getDoc(followerDocRef);

      if (followerDocSnap.exists()) {
        // User document exists, check following array
        const userData = followerDocSnap.data();
        const following = userData.following || [];
        return following.includes(artistAddress);
      } else {
        console.log("address not found");
      }
    } catch (error) {
      console.log("error checking");
    }
  }

};


// check if adddress is blacklisted 

// param address

export const checkIfBlacklisted = async (address) => {
  if (!address) {
    console.error("Address is required to check blacklist status.");
    return false;
  }

  try {
    const docRef = doc(firestoredb, "Users", address);

    const docSnap = await getDoc(docRef);
    console.log(docSnap, "docSnapdocSnapdocSnap");
    console.log(docSnap?.data().isBlackListed);
    return docSnap.exists() ? docSnap.data().isBlackListed || false : false;
  } catch (error) {
    console.error("Error checking if blacklisted:", error);
    return false;
  }
};


export const checkIfBlacklistedByUserName = async (userName) => {
  if (!userName) {
    console.error("Username is required to check blacklist status.");
    return false;
  }

  try {
    const userDocRef = query(collection(firestoredb, "Users"), where("userName", "==", userName));
    const userDocSnap = await getDocs(userDocRef);

    if (!userDocSnap.empty) {
      const userData = userDocSnap.docs[0].data();
      return userData.isBlackListed || false;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking if blacklisted by username:", error);
    return false;
  }
};


// unfollow Artist

export const unfollowArtist = async (userAddress, artistAddress) => {
  try {
    // Reference to the 'followers' collection in Firestore
    const followersCollection = 'followers';

    // Fetch the document of the user who is following
    const userDocRef = doc(firestoredb, followersCollection, userAddress);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      throw new Error(`User with address ${userAddress} not found.`);
    }

    // Update the 'following' array of the user
    await updateDoc(userDocRef, {
      following: arrayRemove(artistAddress)
    });

    // Fetch the document of the artist being followed
    const artistDocRef = doc(firestoredb, followersCollection, artistAddress);
    const artistDocSnap = await getDoc(artistDocRef);

    if (!artistDocSnap.exists()) {
      throw new Error(`Artist with address ${artistAddress} not found.`);
    }

    // Update the 'followers' array of the artist
    await updateDoc(artistDocRef, {
      followers: arrayRemove(userAddress)
    });

  } catch (error) {
    console.log("Error unfollowing artist:");
    // throw error; // Re-throw the error for handling at a higher level
  }
};

// Get followers data based on username

export const getFollowersByAddress = async (address) => {
  // alert("called");
  try {
    const followersDocRef = doc(firestoredb, 'followers', address);
    const followersDocSnap = await getDoc(followersDocRef);

    if (!followersDocSnap.exists()) {
      return false;
    }

    const followersData = followersDocSnap.data();
    const followers = followersData.followers || [];

    return {
      count: followers.length,
      data: followers
    };
  } catch (error) {
    console.log("Error fetching followers:");
    // throw error; // Re-throw the error for handling at a higher level
  }
};
export const getFollowingByAddress = async (address) => {

  try {
    const followingDocRef = doc(firestoredb, 'followers', address);
    const followingDocSnap = await getDoc(followingDocRef);

    if (!followingDocSnap.exists()) {
      return false;
    }

    const followingData = followingDocSnap.data();
    const following = followingData.following || [];

    return {
      count: following.length,
      data: following
    };
  } catch (error) {
    console.error("Error fetching following:", error);
    throw error; // Re-throw the error for handling at a higher level
  }
};


export const getFollowersData = async (username) => {
  try {
    const followersCollection = collection(firestoredb, "followers");
    const querySnapshot = await getDocs(
      query(followersCollection, where("artist_username", "==", username))
    );

    const followersData = querySnapshot.docs.map(
      (doc) => doc.data().follower_username
    );
    const followersCount = followersData.length;
    return { count: followersCount, data: followersData };
  } catch (error) {
    console.error(error);
    return { count: 0, data: [] };
  }
};

// Get following data based on username

export const getFollowingData = async (username) => {
  try {
    const followersCollection = collection(firestoredb, "followers");
    const querySnapshot = await getDocs(
      query(followersCollection, where("follower_username", "==", username))
    );
    const followingData = querySnapshot.docs.map(
      (doc) => doc.data().artist_username
    );
    const followingCount = followingData.length;
    return { count: followingCount, data: followingData };
  } catch (error) {
    console.error(error);

    return { count: 0, data: [] };
  }
};



export const handleNotifications = async (
  username,
  notificationType,
  price = null,
  artName,
  transactionHash = null,
  selectedBlockchain = null,
  artLink,
  To = null,
  isVisible = true,
  admin = false,

) => {
  // Determine the currency based on the selected blockchain
  let currency = selectedBlockchain === "Coston" ? "SGB" : "FLR";

  try {
    // Reference to the "Notifications" collection
    const notificationsCollectionRef = collection(firestoredb, "Notifications");

    // Function to save the notification using addDoc
    const saveNotification = async (username, notificationType, To) => {
      await addDoc(notificationsCollectionRef, {
        username: username,
        price: price,
        currency: currency,
        notificationType: notificationType,
        artName: artName,
        transactionHash: transactionHash,
        selectedBlockchain: selectedBlockchain,
        isRead: false,
        createdAt: Date.now(),
        isVisible: isVisible,
        To: To,
        admin: admin,
        artLink: artLink
      });
    };

    // Generate notifications based on the type
    switch (notificationType) {
      case 'purchase':
        // Notify buyer
        await saveNotification(username, "purchasedBy", To);
        // Notify seller
        await saveNotification(To, "soldBy", username);
        break;

      case 'newOffer':
        // Notify owner (seller)
        await saveNotification(To, "recievedOffer", username);
        // Notify buyer
        await saveNotification(username, "madeOffer", To);
        break;

      case 'offerAccepted':
        // Notify buyer
        await saveNotification(username, "offerAccepted", To);
        break;

      case 'newFollower':
        // Notify the user being followed
        await saveNotification(To, 'newFollower', username);
        break;

      case 'minted':
        // Notify owner
        await saveNotification(username, 'minted', To);
        break;

      case 'listed':
        // Notify owner
        await saveNotification(username, 'listed', To);
        break;

      case 'transfer':
        // Notify previous owner (sender)
        await saveNotification(username, "transferFrom", To);
        // Notify new owner (receiver)
        await saveNotification(To, "transferTo", username);
        break;

      case 'favourite':
        // Notify the user who added to favorites
        await saveNotification(username, "addFavourite", To);
        await saveNotification(To, "favouriteArt", username);
        break;
      case 'claim':
        await saveNotification(username, "claimBy", To);
        await saveNotification(To, "claimFor", username);
        break;
      case 'cancelListing':
        await saveNotification(username, "cancelListing", To);
        break;
      case 'outBid':

        await saveNotification(username, "outBid", To);
        break;
      case 'followActivity':
        await saveNotification(To, "followActivity", username);

        break;
      case 'priceChange':
        await saveNotification(username, 'priceChange', To);

      default:
        console.error("Unknown notification type");
        return false;
    }

    return true;
  } catch (error) {
    console.log(error, "error in notifications");
    return false;
  }
};





export const handleAdminNotifications = async (
  notificationType,
  content,
  isRead = false,
) => {
  try {
    const adminNotificationsCollection = collection(firestoredb, "AdminNotifications");
    await addDoc(adminNotificationsCollection, {
      content: content,
      notificationType: notificationType,
      createdAt: Date.now(),
      isRead: isRead,
    });

    return true;
  } catch (error) {
    console.error("Error saving admin notification:", error);
    return false;
  }
};


export const getAdminNotifications = async () => {
  try {
    const adminNotificationsCollection = collection(firestoredb, "AdminNotifications");

    // You can use a query to order the notifications by creation time
    const q = query(adminNotificationsCollection);

    // Fetch the documents from the collection
    const querySnapshot = await getDocs(q);

    // Extract the data from the documents
    const notifications = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return notifications;
  } catch (error) {
    console.error("Error retrieving admin notifications:", error);
    return [];
  }
};

export const getNotifications = async (username) => {
  try {
    const notificationsCollection = collection(firestoredb, "Notifications");
    const querySnapshot = await getDocs(
      query(notificationsCollection, where("username", "==", username))
    );

    // Check if querySnapshot is empty
    if (querySnapshot.empty) {
      return [];
    }


    const notificationsData = [];

    // Fetch the settings once outside the loop
    let settings = null;
    try {
      settings = await getSettingFirebase(username);
    } catch (error) {

      console.error("Error fetching settings:", error);
    }

    // Process each notification document
    for (const doc of querySnapshot.docs) {
      const notification = doc.data();


      // Add 'id' field to notification object
      notification.id = doc.id;

      // Update notification visibility based on settings or default to true
      if (settings && settings.data && notification.notificationType) {
        const visibility = settings.data[notification.notificationType];

        // Set visibility to true if not specified in settings
        notification.isVisible = visibility !== undefined ? visibility : true;
      } else {
        // If settings or notificationType is not available, default to visible
        notification.isVisible = true;
      }


      // Add notification to the array after updating its visibility
      notificationsData.push(notification);
    }


    // Manually sort notifications by createdAt in descending order
    notificationsData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));


    // Filter notifications based on visibility
    const filteredNotif = notificationsData.filter(item => item.isVisible === true);

    // Return filtered notifications if any, otherwise return all notifications
    return filteredNotif.length > 0 ? filteredNotif : notificationsData;

  } catch (error) {
    console.error("Error getting notifications:", error);
    return [];
  }
};




export const updateNotificationVisiblity = async (Visible, notificationId) => {
  try {
    const notificationRef = doc(firestoredb, "Notifications", notificationId);

    await setDoc(notificationRef, { isVisible: Visible }, { merge: true });
  } catch (error) {
    console.error("Error updating 'isRead' in the database:", error);
  }
};

export const markAllNotificationsAsRead = async (username) => {
  try {
    const notificationsCollection = collection(firestoredb, "Notifications");

    // Query to fetch all notifications for the given username
    const notificationsQuery = query(
      notificationsCollection,
      where("username", "==", username)
    );
    const querySnapshot = await getDocs(notificationsQuery);

    // Update each notification's isRead status to true
    const updatePromises = querySnapshot.docs.map((docSnapshot) => {
      const notificationRef = doc(notificationsCollection, docSnapshot.id);
      return setDoc(notificationRef, { isRead: true }, { merge: true });
    });

    await Promise.all(updatePromises); // Wait for all updates to complete

    // Fetch the updated notifications
    const updatedQuerySnapshot = await getDocs(notificationsQuery);
    const notificationsData = [];

    // Process the updated notifications

    updatedQuerySnapshot.forEach((doc) => {
      const notification = doc.data();
      const createdAtTimestamp = notification.createdAt;
      const createdAtDate = new Date(createdAtTimestamp);
      notification.createdAt = createdAtDate.toLocaleString();

      // Create a new 'id' field and push the 'id' into it
      notification.id = doc.id;

      notificationsData.push(notification);
    });

    toast.success("All notifications marked as read");
    return notificationsData;
  } catch (error) {
    console.error("Error marking notifications as read:", error);
    toast.error("Failed to mark all notifications as read");
    return [];
  }
};
// Function to update 'isRead' for a specific notification

export const updateSingleIsReadInDatabase = async (notificationId) => {

  try {
    const notificationRef = doc(firestoredb, "Notifications", notificationId);

    await setDoc(notificationRef, { isRead: true }, { merge: true });
  } catch (error) {
    console.error("Error updating 'isRead' in the database:", error);
  }
};

export const markAllAdminNotificationAsRead = async () => {
  try {
    // Reference to the AdminNotifications collection
    const notificationsRef = collection(firestoredb, "AdminNotifications");

    // Query to get all documents
    const q = query(notificationsRef);

    // Fetch all documents
    const querySnapshot = await getDocs(q);

    // Create a batch to update all documents in one go
    const batch = writeBatch(firestoredb);

    // Iterate over each document and update the isRead field
    querySnapshot.forEach((docSnapshot) => {
      const notificationDocRef = doc(firestoredb, "AdminNotifications", docSnapshot.id);
      batch.update(notificationDocRef, { isRead: true });
    });

    // Commit the batch update
    await batch.commit();

  } catch (error) {
    console.error("Error marking all notifications as read:", error);
  }
};
export const updatedSingleIsReadAdminNotification = async (notificationId) => {
  try {
    const notificationRef = doc(firestoredb, "AdminNotifications", notificationId);

    await setDoc(notificationRef, { isRead: true }, { merge: true });
  } catch (error) {
    console.error("Error updating 'isRead' in the database:", error);
  }
}
// Adding nft to firebase

export const saveNftDataToFirebase = async (
  username,
  image,
  previewImage,
  name,
  description,
  selectedCollection,
  selectedCollectionId,
  traits,
  selectedBlockchain,
  selectedTags
) => {
  const image1 = await uploadImageStorageAndGetLink(image);
  const previewImg2 = await uploadImageStorageAndGetLink(previewImage);
  try {
    await addDoc(collection(firestoredb, "Nfts"), {
      username: username,
      image: image1,
      previewImg: previewImg2,
      name: name,
      description: description,
      selectedCollection: selectedCollection,
      selectedCollectionId: selectedCollectionId,
      traits: traits,
      selectedBlockchain: selectedBlockchain,
      selectedTags: selectedTags,
      creationTime: Date.now(),
    });
    toast("Data saved successfully");
    return {
      data: {
        Image: image1,
        PreviewImage: previewImg2,
      },
      success: true,
    };
  } catch (error) {
    toast.error(error);
  }
};



// create Art and save to firebase
export const saveArtDataToFirebase = async (
  username,
  name,
  description,
  traits,
  selectedTags,
  artistFee,
  artistAddress,
  isMinted,
  imageUrl,
  previewImageUrl,
  videoUrl,
  url
) => {


  try {

    const docRef = await addDoc(collection(firestoredb, "Arts"), {
      artistName: username,
      image: imageUrl || null,
      previewImg: previewImageUrl || null,
      videoUrl: videoUrl || null,
      name: name,
      description: description,
      traits: traits,
      selectedTags: selectedTags,
      artistFee: artistFee,
      artistAddress: artistAddress,
      isMinted: isMinted,
      url: url,
      creationTime: Date.now(),
    });

    return {
      documentId: docRef.id, // Return the document ID
      data: {
        artistName: username,
        image: imageUrl || null,
        previewImg: previewImageUrl || null,
        videoUrl: videoUrl || null,
        name: name,
        description: description,
        traits: traits,
        selectedTags: selectedTags,
        artistFee: artistFee,
        artistAddress: artistAddress,
        isMinted: isMinted,
        url: url,
        creationTime: Date.now(),
      },

      success: true,
    };

  } catch (error) {
    toast.error(error);
    return { success: false }; // Return false in case of error
  }
};



export const saveArtLastPriceToFirebase = async (artId, lastPrice) => {
  try {
    const artDocRef = doc(firestoredb, "Arts", artId);
    const artDocSnapshot = await getDoc(artDocRef);

    if (artDocSnapshot.exists()) {
      const existingData = artDocSnapshot.data();
      const newData = {
        lastPrice: lastPrice,
        lastPriceUpdateTime: Date.now()
      };

      // Update only if lastPrice is different from existing or does not exist
      if (existingData.lastPrice !== lastPrice) {
        await updateDoc(artDocRef, newData);
      } else {
        console.log(`Last price of art with ID ${artId} already set to ${lastPrice}`);
      }

      return true;
    } else {
      console.log(`No such document with ID ${artId}`);
      return false;
    }
  } catch (error) {
    console.error("Error updating last price: ", error);
    return false;
  }
};


export const getArtIdByUrl = async (url) => {
  try {
    const q = query(collection(firestoredb, "Arts"), where("url", "==", url));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Assuming URLs are unique, take the first document
      const artId = doc.id;
      return artId;
    } else {
      console.log(`No document found with URL ${url}`);
      return null;
    }
  } catch (error) {
    console.error("Error retrieving document by URL: ", error);
    return null;
  }
};
export const getArtLastPriceFromFirebase = async (url) => {

  try {
    const q = query(collection(firestoredb, "Arts"), where("url", "==", url));

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      let price = null;
      querySnapshot.forEach((doc) => {
        const artData = doc.data();

        price = artData.lastPrice;
      });
      return price; // Return the lastPrice found
    } else {
      console.log(`No document found with URL ${url}`);
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAllArts = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let allArts = [];
      const querySnapshot = await getDocs(collection(firestoredb, "Arts"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        allArts.push({ documentId: doc.id, data: doc.data() });
      });
      resolve(allArts);
    } catch (error) {
      console.log(error);
      reject(null);
    }
  });
};

// get art created by Artist
export const getArtCreatedByArtistLength = async (username) => {

  try {
    const querySnapshot = await getDocs(
      query(
        collection(firestoredb, "Arts"),
        where("artistName", "==", username)
      )
    );


    return querySnapshot.size;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

// get all artist Details from firebase

export const getAllArtistDetails = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      let allArtists = [];
      const querySnapshot = await getDocs(
        collection(firestoredb, "artistsDetails")
      );
      querySnapshot.forEach((doc) => {
        allArtists.push({ documentId: doc.id, data: doc.data() });
      });
      resolve(allArtists);
    } catch (error) {
      console.log(error);
      reject(null);
    }
  });
};

export const getArtistDetailsById = async (artistId) => {
  try {
    // Get artist details from artistsDetails collection
    const artistDocRef = doc(firestoredb, "artistsDetails", artistId);
    const artistDocSnap = await getDoc(artistDocRef);
    if (!artistDocSnap.exists()) {
      throw new Error("Artist document not found");
    }
    const artistData = {
      documentId: artistDocSnap.id,
      data: artistDocSnap.data(),
    };

    // Get user data from User table
    let userData = await getUserData(artistId);
    if (!userData) {
      throw new Error("User data not found");
    }

    // Get image URL from user data
    const imageUrl = userData?.image;

    // Add image URL to artist data
    artistData.imageUrl = imageUrl;

    return artistData;
  } catch (error) {

    return null;
  }
};

const artistDAta = async () => {
  await getArtistDetailsById();
};
artistDAta();

export const updateArtDataToFirebase = async (
  username,
  image,
  name,
  description,
  traits,
  selectedTags,
  artistFee,
  artistAddress,
  previewImageUrl,
  isMinted,
  documentId,
  videoUrl
) => {
  try {
    const image1 = await uploadImageStorageAndGetLink(image);
    // const previewImg2 = await uploadImageStorageAndGetLink(previewImage);
    await setDoc(
      doc(firestoredb, "Arts", documentId),
      {
        artistName: username,
        image: image1,
        // previewImg: previewImg2,
        name: name,
        description: description,
        traits: traits,
        selectedTags: selectedTags,
        artistFee: artistFee,
        artistAddress: artistAddress,
        previewImageUrl: previewImageUrl || "",
        videoUrl: videoUrl,
        isMinted: isMinted,
        creationTime: Date.now(),
      },
      { merge: true }
    );

    toast("Art updated successfully");
    return {
      data: {
        Image: image1,
      },
      success: true,
    };
  } catch (error) {
    toast.error(error);
  }
};

// Getting NFTs In collections based on Id

export const getNftsInCollectionFirebase = async (locationDetails) => {
  return new Promise(async (resolve, reject) => {
    try {
      var allNfts = [];
      const q = query(
        collection(firestoredb, "Nfts"),
        where("selectedCollectionId", "==", locationDetails)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        allNfts.push({ documentId: doc.id, data: doc.data() });
      });

      resolve(allNfts);
    } catch (error) {
      console.log(error);
      reject(null);
    }
  });
};

// Getting NFT From Firebase by NFT id

export const getNftDetailsFirebase = async (id) => {
  try {
    const docRef = doc(firestoredb, "Nfts", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const saveCollectionStats = async (data) => {

  try {
    const {
      collectionId,
      artworkCount,
      createdAt,
      creatorEarning,
      volume,
      SGBvolume,
      FLRvolume,
      USDvolume,
      floorPrice,
      owners,
      listedCount,
      saleCount,
      minFloorPrice
    } = data;

    const collectionStatsRef = doc(
      firestoredb,
      "CollectionStats",
      `${collectionId}`
    );

    const docSnap = await getDoc(collectionStatsRef);

    if (docSnap.exists()) {
      // Update existing document
      await setDoc(
        collectionStatsRef,
        {
          collectionId,
          artworkCount,
          createdAt,
          creatorEarning,
          volume,
          SGBvolume,
          FLRvolume,
          USDvolume,
          floorPrice,
          owners,
          listedCount,
          saleCount,
          minFloorPrice: minFloorPrice || 0,
          timestamp: serverTimestamp(),
        },
        { merge: true }
      );
    } else {
      // Create a new document
      await setDoc(collectionStatsRef, {
        collectionId,
        artworkCount,
        createdAt,
        creatorEarning,
        volume,
        SGBvolume,
        FLRvolume,
        USDvolume,
        floorPrice,
        owners,
        listedCount,
        saleCount,
        minFloorPrice: minFloorPrice || 0,
        timestamp: serverTimestamp(),
      });
    }

  } catch (error) {

    console.log("Error saving data collection stats:", error);
  }
};


export const getArtworkHistoryById = async (artworkId) => {
  try {
    const artworkHistoryCollectionRef = collection(firestoredb, "ArtworkHistory");
    const q = query(artworkHistoryCollectionRef, where("artworkId", "==", artworkId));

    const querySnapshot = await getDocs(q);
    const history = [];

    querySnapshot.forEach((doc) => {
      history.push({ id: doc.id, ...doc.data() });
    });

    return { success: true, history };
  } catch (error) {
    console.error("Error getting artwork history:", error);
    return { success: false, error: error.message };
  }
};
export const saveOfferHash = async (artId, bidCount, transactionHash) => {
  try {
    // Create a reference to a new document in the "OfferHash" collection
    const docRef = doc(firestoredb, 'OfferHash', `${transactionHash}`);

    // Create the data to be stored
    const data = {
      artId,
      bidCount,
      transactionHash,
      timestamp: new Date().toISOString() // Add a timestamp if needed
    };

    // Save the document
    await setDoc(docRef, data);

  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

export const getOffersByArtId = async (artId) => {
  try {
    // Create a reference to the "OfferHash" collection
    const offerHashCollectionRef = collection(firestoredb, 'OfferHash');

    // Create a query against the collection
    const q = query(offerHashCollectionRef, where('artId', '==', artId));

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Process the results
    const offers = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    return offers;
  } catch (e) {
    console.error('Error fetching documents: ', e);
    return [];
  }
};
export const saveArtworkHistory = async (transactionHash, address, action, username, artworkId, price, to) => {


  try {
    const artworkHistoryRef = doc(firestoredb, "ArtworkHistory", `${artworkId}_${Date.now()}`);

    await setDoc(artworkHistoryRef, {
      address,
      artworkId,
      action,
      to,
      username,
      transactionHash,
      price: price || null,
      timestamp: serverTimestamp(),
    });


    return { success: true };
  } catch (error) {
    console.error("Error saving artwork history:", error);
    return { success: false, error: error.message };
  }
};
// Get collection stats from firebase

export const getCollectionStats = async (collectionId) => {
  try {
    const collectionStatsRef = doc(
      firestoredb,
      "CollectionStats",
      collectionId
    );
    const collectionStatsDoc = await getDoc(collectionStatsRef);
    if (collectionStatsDoc.exists()) {
      const collectionStatsData = collectionStatsDoc.data();

      return collectionStatsData;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// get All collection stats from firebase

export const getAllCollectionStats = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "CollectionStats")
    );
    const collectionStats = [];
    querySnapshot.forEach((doc) => {
      const collectionStatsData = doc.data();
      collectionStats.push(collectionStatsData);
    });
    return collectionStats;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// save Artsits Stats to firebase
export const saveArtistStats = async (artistAddress, salePrice, saleNetwork) => {


  try {
    const artistStatsRef = doc(firestoredb, "ArtistStats", "tsetsitstesra");

    const docSnap = await getDoc(artistStatsRef);

    // Sale object with price and network
    const sale = {
      price: salePrice,
      saleNetwork: saleNetwork,
    };

    if (docSnap.exists()) {
      const sales = docSnap.data().Sale || [];
      const salesCount = sales.length;

      await setDoc(
        artistStatsRef,
        {
          artistAddress,
          salesCount: salesCount + 1,
          Sale: [...sales, sale],
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
    } else {
      await setDoc(artistStatsRef, {
        artistAddress,
        salesCount: 1,
        Sale: [sale],
        updatedAt: serverTimestamp(),
      });
    }
  } catch (error) {

    console.log("Error saving data:", error);

  }
};


export const saveArtistStats2 = async () => {


  let address = '0x5d30a66B3d161854B20B02B408485E638429fB84';


  try {
    const collectionStatsRef = doc(
      firestoredb,
      "ArtistStats",
      address
    );
    const collectionStatsDoc = await getDoc(collectionStatsRef);
    if (collectionStatsDoc.exists()) {
      const collectionStatsData = collectionStatsDoc.data();

      return collectionStatsData;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
// saveArtistStats();

const testConnection = async () => {
  try {
    const docRef = doc(firestoredb, "ArtistStats", "TestDoc");
    const docSnap = await getDoc(docRef);
  } catch (error) {
    console.error("Error testing connection:", error);
  }
};

testConnection();


// get all artists stats from firebase

export const getAllArtistStats = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "ArtistStats"));
    const artistStats = [];
    querySnapshot.forEach((doc) => {
      const artistStatsData = doc.data();
      const higherSale = Math.max(...artistStatsData.Sale);

      // Sale ["1", "2", "3", "4", "5"]
      const volume = artistStatsData.Sale.reduce((acc, val) => acc + parseFloat(val), 0);

      const AverageSaleValue =
        artistStatsData.Sale.reduce((acc, val) => acc + parseFloat(val), 0) /
        artistStatsData.Sale.length;
      artistStatsData.higherSale = higherSale;
      artistStatsData.AverageSale = AverageSaleValue;

      artistStatsData.volume = volume;
      artistStats.push(artistStatsData);


    });
    return artistStats;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTrendingArtist = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "ArtistStats"));
    const artistStats = [];

    querySnapshot.forEach((doc) => {
      const artistStatsData = doc.data();

      // Initialize variables for calculations
      let higherSale = 0;
      let totalVolume = 0;
      let costonVolume = 0;
      let coston2Volume = 0;
      let costonHigherSale = 0;
      let coston2HigherSale = 0;
      let costonListCount = 0;
      let coston2ListCount = 0;

      artistStatsData.Sale.forEach(sale => {
        // Ensure price is treated as a number
        const price = parseFloat(sale.price);
        const { saleNetwork } = sale;

        totalVolume += price;

        if (price > higherSale) {
          higherSale = price;
        }

        if (saleNetwork === "Coston") {
          costonVolume += price;
          costonListCount += 1;
          if (price > costonHigherSale) {
            costonHigherSale = price;
          }
        } else if (saleNetwork === "Coston2") {
          coston2Volume += price;
          coston2ListCount += 1;
          if (price > coston2HigherSale) {
            coston2HigherSale = price;
          }
        }
      });

      const averageSale = totalVolume / artistStatsData.Sale.length;
      const costonAverageSale = costonListCount > 0 ? costonVolume / costonListCount : 0;
      const coston2AverageSale = coston2ListCount > 0 ? coston2Volume / coston2ListCount : 0;

      // Add the calculated fields to artistStatsData
      artistStatsData.higherSale = higherSale;
      artistStatsData.averageSale = averageSale;
      artistStatsData.costonVolume = costonVolume;
      artistStatsData.coston2Volume = coston2Volume;
      artistStatsData.totalVolume = totalVolume;
      artistStatsData.costonHigherSale = costonHigherSale;
      artistStatsData.costonAverageSale = costonAverageSale;
      artistStatsData.costonListCount = costonListCount;
      artistStatsData.coston2HigherSale = coston2HigherSale;
      artistStatsData.coston2AverageSale = coston2AverageSale;
      artistStatsData.coston2ListCount = coston2ListCount;

      artistStats.push(artistStatsData);
    });

    if (artistStats.length > 1) {
      artistStats.sort((a, b) => b.higherSale - a.higherSale);
    }

    return artistStats;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// Save Admin Stats to firebase

export const saveAdminStats = async (data) => {
  try {
    const {
      sales,
      volume,
      SGBvolume,
      FLRvolume,
      USDvolume,
      commissions,
      whitelistedArtists,
    } = data;
    const adminStatsRef = doc(
      firestoredb,
      "AdminStats",
      `${new Date().getTime()}`
    );
    const docSnap = await getDoc(adminStatsRef);
    if (docSnap.exists()) {
      // Update existing document
      await setDoc(
        adminStatsRef,
        {
          sales,
          volume,
          SGBvolume,
          FLRvolume,
          USDvolume,
          commissions,
          whitelistedArtists,
          timestamp: serverTimestamp(),
        },
        { merge: true }
      );
    } else {
      // Create a new document
      await setDoc(adminStatsRef, {
        sales,
        volume,
        SGBvolume,
        FLRvolume,
        USDvolume,
        commissions,
        whitelistedArtists,
        timestamp: serverTimestamp(),
      });
    }
  } catch (error) {
    console.error("Error saving data:", error);
  }
};

// Get Admin stats from firebase

export const getAdminStats = async () => {
  try {
    const adminStatsRef = doc(
      firestoredb,
      "AdminStats",
      `${new Date().getTime()}`
    );
    const adminStatsDoc = await getDoc(adminStatsRef);
    if (adminStatsDoc.exists()) {
      const adminStatsData = adminStatsDoc.data();
      return adminStatsData;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

// get sales and shitelisted stats

export const getSalesAndWhitelistedStats = async () => {
  try {
    // read all collection stats document and add saleCount

    const querySnapshotCS = await getDocs(
      collection(firestoredb, "CollectionStats")
    );
    let sales = 0;
    querySnapshotCS.forEach((doc) => {
      const collectionStats = doc.data();
      sales += collectionStats.saleCount;
    });

    // read all artistDetails document and add whitelistedCount

    const querySnapshotAD = await getDocs(
      collection(firestoredb, "artistsDetails")
    );
    let whitelistedArtists = 0;
    querySnapshotAD.forEach((doc) => {
      const artistDetails = doc.data();
      if (
        artistDetails.approved === true &&
        artistDetails.isBlacklisted === false
      ) {
        whitelistedArtists += 1;
      }
    });

    return { sales, whitelistedArtists };
  } catch (error) {
    console.error(error);
    return { sales: 0, whitelistedArtists: 0 };
  }
};

// Get Favourite Users

export const getFavoritesForUser = async (username) => {
  try {
    const favoritesCollection = collection(firestoredb, "favorites");

    // Query to retrieve favorite documents where userName matches the given username
    const q = query(favoritesCollection, where("userName", "==", username));

    // Get the matching favorite documents
    const querySnapshot = await getDocs(q);

    const favorites = [];

    // Create an array of promises for fetching NFT details
    const nftPromises = querySnapshot.docs.map(async (docRef) => {
      const favoriteData = docRef.data();
      const nftId = favoriteData.nftId;
      return getNftDetailsFirebase(nftId);
    });

    // Wait for all promises to resolve and add results to favorites
    const nftDetails = await Promise.all(nftPromises);
    favorites.push(...nftDetails);

    return favorites;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateUserEmailAndVerified = async (address, newEmail) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address); // Retrieve existing user data

    if (userDocSnap) {
      if (userDocSnap?.userMail) {
      } else {
        if (userDocSnap) {
          // Update email and verified status
          const updatedData = {
            ...userDocSnap,
            userMail: newEmail,
            verified: false,
          };
          await updateDoc(userDocRef, updatedData);

          return updatedData;
        } else {
          return null;
        }
      }
      return "username already exists";
    } else {
      await setDoc(
        doc(firestoredb, "Users", address),
        {
          userMail: newEmail,
          verified: false,
          isuserMailSend: false,
        },
        { merge: true }
      );
      return "success";
    }
  } catch (err) {
    console.error("Error updating user email and verified status:", err);
    return null;
  }
};

export const updateVerifiedStatus = async (address, verified) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address); // Retrieve existing user data

    if (userDocSnap) {
      // Update verified status
      const updatedData = {
        ...userDocSnap,
        verified: verified,
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating verified status:", err);
    return null;
  }
};

// update user Email in firebase
export const updateUserMail = async (address, userMail) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address); // Retrieve existing user data

    if (userDocSnap) {
      // Update userMail
      const updatedData = {
        ...userDocSnap,
        userMail: userMail,
        isuserMailSend: true,
        verified: false,
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating user mail:", err);
    return null;
  }
};

export const updateUsername = async (address, newUsername) => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "Users"));
    let usernameExists = false;

    querySnapshot.forEach((doc) => {
      const userData = doc.data();

      if (doc.id !== address && userData.userName === newUsername) {
        usernameExists = true;
        return;
      }
    });
    if (usernameExists) {
      // alert("already exist")
      return false;
    } else {
      try {
        const userDocRef = doc(firestoredb, "Users", address);
        const userDocSnap = await getDoc(userDocRef);

        // If the user document doesn't exist, create a new one
        if (!userDocSnap.exists()) {
          const newData = {
            userName: newUsername,
            // Add any other fields you want to initialize
          };

          await setDoc(userDocRef, newData); // Create the new document
          // alert("Document created successfully");
          return true;
        } else {
          // Document exists, update the username
          const updatedData = {
            ...userDocSnap.data(), // Use .data() to get the existing data
            userName: newUsername,
          };
          await updateDoc(userDocRef, updatedData);
          // alert("Document updated successfully");
          return true;
        }
      } catch (err) {
        console.error("Error updating username:", err);
        return null;
      }
    }
  } catch (err) {
    console.error("Error updating username:", err);
    return null;
  }
};

export const updateToken = async (address, token) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address); // Retrieve existing user data

    if (userDocSnap) {
      // Update token
      const updatedData = {
        ...userDocSnap,
        token: token,
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating token:", err);
    return null;
  }
};
export const updateWhitelistStatus = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address);

    if (userDocSnap) {
      // Check if the user is already whitelisted
      if (userDocSnap.isWhiteListed) {
        // Artist is already whitelisted, show alert
        toast.info("Artist is already whitelisted!");
        return userDocSnap;
      }

      // Update whitelist status if not already whitelisted
      const updatedData = {
        ...userDocSnap,
        isWhiteListed: true, // Update isWhiteListed field to true
        isBlackListed: false,
        creationTime: Date.now(),
      };

      // Also update ProfileSetting set private to false
      const profileSettingRef = doc(firestoredb, "ProfileSettings", address);
      const profileSettingSnap = await getDoc(profileSettingRef);
      if (profileSettingSnap.exists()) {
        await updateDoc(profileSettingRef, { private: false });
      }

      await updateDoc(userDocRef, updatedData);
      toast.success("Artist added successfully!");

      return updatedData;
    } else {

      return null;
    }
  } catch (err) {
    console.error("Error updating whitelist status:", err);
    return null;
  }
};

export const removeWhitelistStatus = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address);

    if (userDocSnap) {
      // User document exists, update whitelist status
      const updatedData = {
        ...userDocSnap,
        isWhiteListed: false, // Update isWhiteListed field to false
        // isBlackListed: true,
        creationTime: Date.now(),
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error removing whitelist status:", err);
    return null;
  }
};

export const addArtistToBlackList = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address);

    if (userDocSnap) {
      // User document exists, update whitelist status
      const updatedData = {
        ...userDocSnap,
        isBlackListed: true, // Update isWhiteListed field to true
        isWhiteListed: false,
        creationTime: Date.now(),
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating whitelist status:", err);
    return null;
  }
};

export const removeArtistFromBlackList = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Users", address);
    const userDocSnap = await getUserData(address);

    if (userDocSnap) {
      const updatedData = {
        ...userDocSnap,
        isBlackListed: false, // Update isWhiteListed field to true
        isWhiteListed: false,

      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating whitelist status:", err);
    return null;
  }
};



export const getCollectionByAddress = async (address) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(firestoredb, "Collections"),
        where("address", "==", address)
      )
    );

    const collectionsByAddress = [];

    querySnapshot.forEach((doc) => {
      const collectionData = doc.data();
      collectionData.documentId = doc.id; // Add document ID to collection data
      collectionsByAddress.push(collectionData);
    });

    return collectionsByAddress;
  } catch (error) {
    console.error("Error fetching collections by address:", error);
    throw new Error("Error fetching collections by address");
  }
};

export const getCollection = async (address) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(firestoredb, "Collections"),
        where("address", "==", address)
      )
    );
    const collectionsByAddress = [];

    querySnapshot.forEach((doc) => {
      collectionsByAddress.push(doc.data());
    });

    return collectionsByAddress;
  } catch (error) {
    console.error("Error fetching collections by address:", error);
    throw new Error("Error fetching collections by address");
  }
};

export const addCollectionToBlackList = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Collections", address);
    const userDocSnap = await getCollection(address);

    if (userDocSnap && userDocSnap.length > 0) {
      const existingData = userDocSnap[0];
      const updatedData = { ...existingData, isBlackList: true };

      try {
        await updateDoc(userDocRef, updatedData);

        return updatedData;
      } catch (error) {
        console.error("Error updating blacklist status:", error);
        throw new Error("Error updating blacklist status");
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error adding collection to blacklist:", error);
    throw new Error("Error adding collection to blacklist");
  }
};

export const removeCollectionFromBlacklist = async (address) => {
  try {
    const userDocRef = doc(firestoredb, "Collections", address);
    const userDocSnap = await getCollectionsByAddress(address);

    if (userDocSnap) {
      // User document exists, update whitelist status
      const updatedData = {
        ...userDocSnap,
        isBlacklisted: false, // Update isWhiteListed field to true
      };
      await updateDoc(userDocRef, updatedData);
      return updatedData;
    } else {
      return null;
    }
  } catch (err) {
    console.error("Error updating whitelist status:", err);
    return null;
  }
};

/**  unlink email */

// export const unlinkemail = async (user, address) => {
export const unlinkemail = async (address) => {
  try {
    await setDoc(
      doc(firestoredb, "Users", address),
      {
        userMail: deleteField(),
        isuserMailSend: false,
        verified: false, // Setting verified to false
      },
      { merge: true }
    );
    logout();
  } catch (error) {
    console.error("Error unlinking email:", error);
    // Handle error if needed
  }
};

// save top artist to firebase

export const saveTopArtist = async (data) => {
  try {
    const { artistAddress, topImage } = data;

    // This is the image reference which includes the image name and storage reference of Firebase storage
    const imageRef = ref(storage, Date.now() + "image");

    // Upload image to Firestore storage
    const snapshot = await uploadBytes(imageRef, topImage);

    // Get the URL of the image
    const imageUrl = await getDownloadURL(imageRef);
    // Set the document in the "featuredUsers" collection
    await setDoc(
      doc(firestoredb, "topUsers", artistAddress),
      {
        image: imageUrl,
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get top artist from firebase and return last added user

export const getTopArtist = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "topUsers"));
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        image: doc.data().image, // Get the 'image' field
      });
    });

    return users[users.length - 1];
  } catch (err) {
    console.error(err);
    return null;
  }
};

// save top collection to firebase

export const saveTopCollection = async (data) => {
  try {
    const { collectionAddress, topImage } = data;
    // This is the image reference which includes the image name and storage reference of Firebase storage
    const imageRef = ref(storage, Date.now() + "image");

    // Upload image to Firestore storage
    const snapshot = await uploadBytes(imageRef, topImage);

    // Get the URL of the image
    const imageUrl = await getDownloadURL(imageRef);
    // Set the document in the "featuredUsers" collection
    await setDoc(
      doc(firestoredb, "topCollections", collectionAddress),
      {
        image: imageUrl,
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get top collection from firebase and return last added user

export const getTopCollection = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "topCollections")
    );
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        image: doc.data().image, // Get the 'image' field
      });
    });

    return users[users.length - 1];
  } catch (err) {
    console.error(err);
    return null;
  }
};

// save top series to firebase

export const saveTopSeries = async (data) => {
  try {
    const { seriesAddress, topImage } = data;
    // This is the image reference which includes the image name and storage reference of Firebase storage
    const imageRef = ref(storage, Date.now() + "image");

    // Upload image to Firestore storage
    const snapshot = await uploadBytes(imageRef, topImage);

    // Get the URL of the image
    const imageUrl = await getDownloadURL(imageRef);
    // Set the document in the "featuredUsers" collection
    await setDoc(
      doc(firestoredb, "topSeries", seriesAddress),
      {
        image: imageUrl,
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get top series from firebase and return last added user

export const getTopSeries = async () => {
  try {
    const querySnapshot = await getDocs(collection(firestoredb, "topSeries"));
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        image: doc.data().image, // Get the 'image' field
      });
    });

    return users[users.length - 1];
  } catch (err) {
    console.error(err);
    return null;
  }
};

// save top collectors to firebase

export const saveTopCollectors = async (data) => {
  try {
    const { collectorAddress, topImage } = data;
    // This is the image reference which includes the image name and storage reference of Firebase storage
    const imageRef = ref(storage, Date.now() + "image");

    // Upload image to Firestore storage
    const snapshot = await uploadBytes(imageRef, topImage);

    // Get the URL of the image
    const imageUrl = await getDownloadURL(imageRef);
    // Set the document in the "featuredUsers" collection
    await setDoc(
      doc(firestoredb, "topCollectors", collectorAddress),
      {
        image: imageUrl,
      },
      { merge: true }
    );
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

// get top collectors from firebase and return last added user

export const getTopCollectors = async () => {
  try {
    const querySnapshot = await getDocs(
      collection(firestoredb, "topCollectors")
    );
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id, // Document ID (name)
        image: doc.data().image, // Get the 'image' field
      });
    });

    return users[users.length - 1];
  } catch (err) {
    console.error(err);
    return null;
  }
};
export const saveLikedArtwork = async (address, artworkId) => {
  try {
    const docRef = doc(firestoredb, "likedArtworks", address);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const likes = docSnap.data().likedArtworks || [];

      if (likes.includes(artworkId)) {
        const index = likes?.indexOf(artworkId);
        if (index > -1) {
          likes.splice(index, 1);
        }

        await setDoc(
          docRef,
          {
            likedArtworks: likes,
          },
          { merge: true }
        );
        toast.warning("Removed from favourites");

        return false;
      } else {
        await setDoc(
          docRef,
          {
            likedArtworks: [...likes, artworkId],
          },
          { merge: true }
        );

        return true;
      }
    } else {
      await setDoc(
        docRef,
        {
          likedArtworks: [artworkId],
        },
        { merge: true }
      );

      return true;
    }
  } catch (err) {
    console.error("Error in saveLikedArtwork:", err);
    return false;
  }
};

export const getAllLikedArtwork = async (address) => {
  if (address) {

  }
  try {
    const likedArtworkRef = doc(firestoredb, "likedArtworks", address);
    const likedArtworkDoc = await getDoc(likedArtworkRef);
    if (likedArtworkDoc.exists()) {
      const likedArtworkData = likedArtworkDoc.data();
      return likedArtworkData;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};



// Function to send verification email
const sendVerificationEmail = async (userId, email) => {
  try {
    const userRef = doc(firestoredb, "Users", userId);
    await setDoc(userRef, {
      email: email,
      isUserMailSend: true,
      verified: false
    }, { merge: true });

    // Send verification email
    const user = auth.currentUser;
    if (user) {
      await sendEmailVerification(user);
      return {
        success: true,
        message: "Verification email sent successfully"
      };
    }
    return {
      success: false,
      message: "No authenticated user found"
    };
  } catch (error) {
    console.error("Error sending verification email:", error);
    return {
      success: false,
      error: error.message
    };
  }
};

// Function to verify email
const verifyEmail = async (userId, email) => {
  try {
    const userRef = doc(firestoredb, "Users", userId);
    await setDoc(userRef, {
      verified: true
    }, { merge: true });

    return {
      success: true,
      message: "Email verified successfully"
    };
  } catch (error) {
    console.error("Error verifying email:", error);
    return {
      success: false,
      error: error.message
    };
  }
};


const SaveSiteSetting = async (data) => {
  try {
    const docRef = doc(firestoredb, "SiteSetting", "siteSetting");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await setDoc(docRef, data, { merge: true });
    } else {
      await setDoc(docRef, data);
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

const getSiteSetting = async () => {
  try {
    const docRef = doc(firestoredb, "SiteSetting", "siteSetting");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        message: data.message,
        spotActive: data.spotActive
      }
    }
    return {
      message: "",
      spotActive: false
    };
  } catch (err) {
    console.error(err);
    return {
      message: "",
      spotActive: false
    };
  }
}
const logout = () => {
  signOut(auth);
};

export {
  auth,
  app,
  storage,
  firestoredb,
  secondaryAuth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
  sendVerificationEmail,
  verifyEmail,
  SaveSiteSetting,
  getSiteSetting
};
