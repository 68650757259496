import React, { cloneElement, useContext, useEffect, useState } from "react";
import "../../style/main.scss";
import verified from "../../assets/icon/verified-artist-small.svg";
import sgb from "../../assets/icon/SGB.svg";
import flr from "../../assets/icon/FLR.svg";
import profile from "../../assets/icon/profile-picture.svg";
import loader from "../../assets/icon/loader-medium.svg";
import userProfile from "../../assets/icon/profile-picture.svg";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import ArtistPopUp from "../shared/artistpopup";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getAllUsers,
  getCollectionDetailsFirebase,
  getCollections
} from "../../firebase/firebase";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { fetchNFTMetadata } from "../../Services/nft";
import { useAccount } from "wagmi";
import CardSkelton from "../shared/cardSkelton";
import { NFTContext } from "../context/appContext";

import Likes from "./likes";
import { Popover } from "antd";
import config from "../../config";
const LikesGrid = (props) => {
  // contract address of marketplace

  const { listedNfts, updateItemNumbers, itemNumber } = useContext(NFTContext);
  const [nfts, setNfts] = useState([]);


  const { marketplace, erc20 } = config;
  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  const [collectedArts, setCollectedArts] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { address: accountAddress } = useAccount();
  const [address, setAddress] = useState(accountAddress);
  const [collections, setCollections] = useState([]);
  const [nftList, setNftList] = useState([]);
  const [data, setData] = useState([]);




  const getAllListNFTData = async (selectedNetwork, web3) => {
    // const accounts = await web3.eth.getAccounts();
    if (collections && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // alert("in listed");
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();

      // setListedNfts(data);
      return data;
    } else {
      // setListedNfts([]);
    }
  };

  const [owner, setOwner] = useState(null);

  const getNftMetadataList = async (tokens_uri, network) => {
    setNfts([]);
    const nftMetadata = [];

    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri;
        try {
          const json = await fetchNFTMetadata(uri);

          const existingIndex = nftMetadata.findIndex(
            (item) => item.uri === uri
          );
          if (existingIndex === -1 && json) {
            nftMetadata.push({
              data: token,
              metadata: {
                uri: uri,
                data: json.data,
              },
            });
          }
        } catch (error) {
          console.error(`Failed to fetch metadata from ${uri}:`, error);
        }
      })
    );

    return nftMetadata;
  };

  // step no 2
  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork, id) => {
    let web;
    if (selectedNetwork == config.networks.flare) {
      web = new Web3(config.rpc.flr);
    } else {
      web = new Web3(config.rpc.songbird);
    }
    let nftListDetails = [];
    let nftArray = [];

    if (web) {
      const contract = new web.eth.Contract(mintContractABI, contractAddress);
      // const nftData = await contract.methods.getTokenIdsByCollection(id).call();

      let listedNfts = await getAllListNFTData(selectedNetwork, web);
      nftData?.map((item) => {
        let isSaleListed = false;
        let isOfferListed = false;

        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            nftListDetails.push({
              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
            };
          }
        }
      });
    }
    // setNftList(nftListDetails);
    return nftListDetails;
  };

  // fet data for nfts collected by user (connected address)
  const fetchDataForAllContracts = async () => {
    let contractDataArray = [];

    for (const item of collections) {
      let data = await fetchDataForContract(
        item?.data.contractAddress,
        item?.data.selectedNetwork,
        item?.documentId
      );
      if (data?.length > 0) {
        contractDataArray.push(data);
      }
    }

    const flattenedTokens = contractDataArray.flat();

    const userslist = await getAllUsers();

    let res = await getNftMetadataList(flattenedTokens);

    console.log(res, "respone");
    // setNftList(res);



    let filterBlacklistedOwners = res?.filter(

      (item) => {

        // Find the user with the matching ID
        const owner = userslist.find((user) => user.id === item.data.newOwner);

        // Check if the owner is found and is not blacklisted
        return owner && owner.isBlackListed == false;
      }

    )
    if (filterBlacklistedOwners) {
      updateItemNumbers(filterBlacklistedOwners.length);
      // props.updateItems(filterBlacklistedOwners.length);
      setNftList(filterBlacklistedOwners); // Setting the state here
    }
  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    }
  }, [collections, nftData]);


  const getUserCollections = async () => {
    let userslist = await getAllUsers();
    const usercollections = await getCollections();
    let filteredCol = usercollections.filter((item) => {
      // Find the user with the matching ID
      const owner = userslist.find((user) => user.id === item.data.address);
      // Check if the owner is found and is not blacklisted
      return owner && owner.isBlackListed == false;
    })

    setCollections(filteredCol);
  };
  useEffect(() => {
    getUserCollections();
  }, [address]);

  useEffect(() => {
    setData(nftList);

  }, [listedNfts, nftList]);





  const handleImageLoad = () => {
    setLoaded(true);
  };

  const updateAddressFromProps = () => {
    if (props.id) {
      setAddress(props.id);
    }
  };

  useEffect(() => {
    updateAddressFromProps();
  }, [props]);

  // get best offer price
  const fetchDataAndUpdateDetails = async () => {
    if (props?.listedNft) {
      let data = props?.listedNft.flat();
      for (let a of data) {
      }
      try {
        const web3 = new Web3(window.ethereum);
        const updatedDetails = await Promise.all(
          data
            .filter((item) => item?.isOfferListed)
            .map(async (item) => {
              const MarketplaceAddress =
                item?.data.selectedBlockchain === "Coston"
                  ? Marketplace_coston_contractAddress
                  : Marketplace_coston2_contractAddress;

              const contract = new web3.eth.Contract(
                marketplaceContractABI,
                MarketplaceAddress
              );
              try {
                const biddingHistoryCoston = await contract.methods
                  .getBiddingHistory(item?.listCount)
                  .call();
                if (biddingHistoryCoston && biddingHistoryCoston.length > 0) {
                  const maxPrice = calculateMaxPrice(biddingHistoryCoston);
                  return { ...item, maxPrice: maxPrice / 1e18 }; // Converting to ether
                }
              } catch (error) {
                console.error("Error fetching bidding history:", error);
                // Handle error fetching bidding history
              }
            })
        );
        setNftData((prevDetails) => {
          const unchangedItems = data.filter(
            (prevItem) => !prevItem.isOfferListed
          );
          const mergedDetails = [
            ...unchangedItems,
            ...updatedDetails.filter(Boolean),
          ];
          return mergedDetails;
        });
      } catch (error) {
        console.error("Error fetching data and updating details:", error);
        // Handle error fetching data and updating details
      }
    }
  };
  // Function to calculate the maximum price from bidding history
  const calculateMaxPrice = (biddingHistoryArray) => {
    let maxPrice = 0;
    for (const biddingHistory of biddingHistoryArray) {
      for (const key in biddingHistory) {
        if (Object.prototype.hasOwnProperty.call(biddingHistory, key)) {
          const currentPrice = parseFloat(biddingHistory[key]);
          if (!isNaN(currentPrice) && currentPrice > maxPrice) {
            maxPrice = currentPrice;
          }
        }
      }
    }
    return maxPrice;
  };
  useEffect(() => {
    if (props?.listedNft) {
      setCollectedArts(props?.listedNft);
      const fetchdata = async () => {
        await fetchDataAndUpdateDetails();
      };
      fetchdata();
    }
  }, [props?.listedNft]);

  const navigate = useNavigate();

  // get nft of connected address

  const getUserNftsFromContract = async (
    contractAddress,
    Address,
    uri,
    selectedBlockchain
  ) => {
    let web3_1;
    if (selectedBlockchain === config?.networks?.songbird) {
      web3_1 = new Web3(config.rpc.songbird);
    } else if (selectedBlockchain === config?.networks?.flare) {
      web3_1 = new Web3(config.rpc.flr);
    }
    try {
      if (web3_1 !== null) {
        const contract = new web3_1.eth.Contract(
          mintContractABI,
          contractAddress
        );
        const contractOwner = await contract.methods.owner().call();

        return new Promise((resolve, reject) => {
          contract.methods
            .getTokenIdsByCollection(Address)
            .call({ from: contractOwner }, async (error, result) => {
              if (error) {
                console.error(error);
                reject(error);
              } else {
                if (result?.length > 0) {
                  for (let a of result) {
                    if (a.uri == uri) {
                      resolve(a.tokenId);
                    }
                  }
                } else {
                  reject("Error: No result found");
                }
              }
            });
        });
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // navigate to single art

  const navigateTo = async (item) => {
    const result = await getCollectionDetailsFirebase(
      item?.data.data?.selectedCollectionId
    );
    try {
      let TokenId = await getUserNftsFromContract(
        result?.contractAddress,
        item?.data.data?.selectedCollectionId,
        item.data?.uri,
        item?.data?.data?.selectedBlockchain
      );

      let tokenId = TokenId;
      navigate(`/artwork/${item?.data?.data?.selectedBlockchain?.split(" ")[0]}/${result?.contractAddress}/${tokenId}/`);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  const [userData, setUserData] = useState([]);

  // get user from localstorage

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (adr) => {
    let artistName = "";
    if (accountAddress === adr) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === adr) {
          artistName =
            user?.userName?.length > 18
              ? user?.userName.substring(0, 18) + "..."
              : user?.userName;
        }
      });
    }

    return "@" + artistName;
    // return "@" + artistName;
  };

  // get artist image by address

  const getArtistImage = (accountAddress) => {
    if (!userData) {
      return;
    }
    let artistImage = userProfile;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = userProfile;
        }
      }
    });
    return artistImage;
  };





  return (
    <>
      <div
        className={
          props.flag ? "d-flex align-item-center justify-content-between" : ""
        }
      >
        {props.loading ? (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
            <CardSkelton />
          </div>
        ) : (
          <div
            className={`grid-display artworkGrid  ${props.flag ? "w-75 active" : ""
              }`}
          >
            {data &&
              data.map((item, index) => {
                if (item?.data?.isListed === false || item === undefined) {
                  return (
                    <>
                      <CardSkelton />
                      <CardSkelton />
                      <CardSkelton />
                      <CardSkelton />
                    </>
                  );
                } else {
                  return (
                    <div className="collection-grid-card" key={index}>
                      <div className="card-head  ">
                        <div className="user-img">
                          <img
                            src={getArtistImage(item?.data?.data?.artistAddress)}
                            alt="profile image"
                            className="img-100"
                          />
                        </div>

                        <div
                          onClick={() => navigateTo(item, "listed")}
                          className="user-name"
                        >
                          <p className="body-large hover-underline pointer">
                            {item?.data?.data?.artName
                              ? item?.data?.artName?.length > 18
                                ? `${item?.data?.data.artName.substring(0, 18)}...`
                                : item?.data?.data?.artName
                              : "Art Name"}
                          </p>

                          <Popover
                            placement="top"
                            trigger="hover"
                            content={
                              <>
                                <ArtistPopUp
                                  userProfile={getArtistImage(
                                    item?.data?.data?.artistAddress
                                  )}
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.data?.data?.artistAddress
                                  )} // passing artist as prop
                                />
                              </>
                            }
                          >
                            <p className="fw-bold text-medium-grey hoverBlack   ">
                              {getArtistNamebyAdress(item?.data?.data?.artistAddress)}
                              <img
                                src={verified}
                                alt="verified"
                                className="img-18 ms-1"
                              />
                              {/* artsit pop up here */}
                            </p>
                          </Popover>
                        </div>
                      </div>

                      <div className="card-body">
                        <div
                          className="art-img"
                          onClick={() => navigateTo(item, "listed")}
                        >
                          {item?.data?.data?.image ? (
                            <img
                              src={item?.data?.data?.image}
                              alt="art"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          ) : (
                            <img
                              src={item?.data?.data?.previewImg}
                              alt="Preview"
                              className={`img-100 artwork-hover ${loaded ? "loaded" : ""
                                }`}
                              onLoad={handleImageLoad}
                            />
                          )}
                        </div>



                        {item.data?.data?.selectedBlockchain ===
                          config.networks.songbird && item?.listedData?.price ? (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(item?.data?.data?.selectedBlockchain)}
                                id="tooltip1"
                              >
                                <img src={sgb} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip1(
                                item.data?.isSaleListed &&
                                item?.data?.listedData?.price / 1000000000000000000
                              )}
                              id="tooltip1"
                            >
                              <div className="sgb">
                                <img src={sgb} alt="sgb" />
                                <p className="body-large text-white ms-1">
                                  {item.data?.isSaleListed &&
                                    item?.data?.listedData?.price / 1000000000000000000}
                                </p>
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : item.data.data?.selectedBlockchain ===
                          config.networks.flare && item?.data?.listedData?.price ? (
                          <>

                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(item?.data?.data?.selectedBlockchain)}
                                id="tooltip1"
                              >
                                <img src={flr} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                            <OverlayTrigger
                              placement="top"
                              overlay={tooltip1(
                                item.data?.isSaleListed &&
                                item?.data?.listedData?.price / 1000000000000000000
                              )}
                              id="tooltip1"
                            >
                              <div className="sgb">
                                <img src={flr} alt="FLR" />
                                <p className="body-large text-white ms-1">
                                  {item.data?.isSaleListed &&
                                    item?.data?.listedData?.price / 1000000000000000000}
                                </p>
                              </div>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <></>
                        )}













                        {/* 
                        {item?.data?.selectedBlockchain === "Coston" ? (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.data?.data?.LikesselectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={sgb} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="chain-logo ">
                              <OverlayTrigger
                                placement="top"
                                overlay={tooltip2(
                                  item?.data?.data?.selectedBlockchain
                                )}
                                id="tooltip1"
                              >
                                <img src={flr} alt="chain logo" />
                              </OverlayTrigger>
                            </div>
                          </>
                        )} */}
                      </div>

                      <div className="card-footer">
                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              <ArtistPopUp
                                userProfile={userProfile}
                                verified={verified}
                                artistName={getArtistNamebyAdress(
                                  item?.data?.data?.artistAddress
                                )}
                              />
                            </>
                          }
                        >
                          <div className="owner">
                            <p className="body-medium text-medium-grey ">
                              Owner
                            </p>
                            <label className="medium text-black no-text-transform">
                              <img src={profile} alt="profile" />
                              {getArtistNamebyAdress(item?.data?.data?.artistAddress)}
                              {/* artsit pop up here */}
                            </label>
                          </div>
                        </Popover>
                        {/* <div className="offer">
                        <p className="body-large text-bold-black ">
                          Listed For Sale
                        </p>
                      </div> */}


                        <div className="offer">
                          {item?.data.data?.lastPrice && (
                            <p className="body-medium text-medium-grey ">
                              {item?.data?.isOfferListed
                                ? "Best Offer"
                                : "Last Sale Price"}
                            </p>
                          )}
                          <label className="medium text-black">
                            {item?.data.data?.lastPrice / 1000000000000000000 ||
                              item.data?.maxPrice ||
                              "--"}
                          </label>
                        </div>


                        {/* <div className="offer">
                      <p className="body-medium text-medium-grey ">
                        Best Offer
                      </p>
                      <label className="medium text-black">
                        <img src={sgb} alt="profile" />
                        {item.offer}
                      </label>
                    </div> */}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>

      <div className="content-loader rotate-360 d-none">
        <img src={loader} alt="loader" />
      </div>
    </>
  );
};

export default LikesGrid;
