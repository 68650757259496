

import React, { createContext, useEffect, useState } from 'react';
import { getCollections } from '../../firebase/firebase';
import Web3 from 'web3';
import mintABI from "../../abis/SafeMint/abi.json";
import marketplaceContractABI from "../../abis/marketplace.json";
import mainnetABI from "../../abis/MainnetCollections/abi.json";
import config from '../../config';
import axios from 'axios';
import _ from 'lodash';
import { fetchNFTMetadata } from '../../Services/nft';
export const NFTContext = createContext();

export const NFTProvider = ({ children }) => {

  const { marketplace, erc20 } = config;
  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;


  // context states
  const [address, setAddress] = useState('');
  const [nftList, setNftList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [artModal, setArtModal] = useState(false);
  const [createdArtworks, setCreatedArtworks] = useState(0);
  const [reloadAfterTransfer, setReloadAfterTransfer] = useState(false);
  const [search, setSearch] = useState(false);
  const [reloadProfile, setReloadProfile] = useState(false);
  const [clearTags, setClearTags] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [memberCollection, setMemberCollection] = useState([]);
  const [mainnetCollection, setMainnetCollection] = useState(config.mainnetCollections);
  const [userMembership, setUserMemberShip] = useState(null)
  const [profAddress, setProfAddress] = useState(null);
  const [collections, setCollections] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [nft, setNft] = useState(null);
  const [sgbUsd, setSgb] = useState(null);
  const [flrUsd, setFlr] = useState(null)
  const [collectedNumber, setCollectedNumber] = useState(null);
  const [collection, setCollection] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [itemNumberLike, setItemNumberLike] = useState(null);

  let key = process.env.REACT_APP_CRYPTO_KEY;
  const currentEnv = process.env.REACT_APP_VERCEL_ENV;


  const updateItemNumbers = (value) => {
    setItemNumberLike(value);
  }
  const updateProfAddress = (value) => {
    setProfAddress(value);
  }

  const reload = () => {
    setReloadProfile(!reloadProfile);
  }

  const updateAddress = (value) => {
    setAddress(value);
  }

  const change = (val) => {
    setIsChange(val);
  }

  const updateNftList = (newNftList) => {
    setNftList(newNftList);
  };

  const updateModal = () => {
    setShowModal(!showModal);
  }

  const updateCreated = (value) => {
    setCreatedArtworks(value);
  }
  const udpateArtModal = () => {
    setArtModal(!artModal);
  }

  const updateTransfer = (value) => {
    setReloadAfterTransfer(value);
  }

  const updateSearch = (value) => {
    setSearch(!search);
  }

  const updatedClearTags = (value) => {
    setClearTags(!clearTags);
  }

  const updateTagsData = (value) => {
    setTagsData(value);
  }

  // get all nfts
  const setData = async (value) => {
    setListedNfts(value);
  }
  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };


  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);


  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      window.location.reload();
    };
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    }
    // Clean up the event listener when the component unmounts
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  }, []);


  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      // Filter user collections with the specified names
      const filteredMemberCollections = usercollections.filter(
        (collection) =>
          collection.address !== address &&
          [
            'The Fat Cats',
            'The Fat Leopards',
            'The Fat Tigers',
            'The Fat Kittens',
          ].includes(collection.data.name)
      );
      setMemberCollection(filteredMemberCollections);
      const filteredCollections = usercollections.filter(
        (collection) => collection.address !== address
      );
      setCollections(filteredCollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };

  useEffect(() => {
    getUserCollections();
  }, [address, profAddress]);

  const getAllListNFTData = async (selectedNetwork, web3) => {
    if (collectionDetails && web3 != null) {
      let MarketplaceAddress;
      if (selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      const data = await contract.methods.getAllListedNfts().call();
      return data;
    } else {
      setListedNfts([]);
    }
  };


  // check membership using mainnet collectiosn
  const checkMembershipQualificationMainnet = async () => {
    let fatCatsOwned = 0;
    let fatLeopardsOwned = 0;
    let fatTigersOwned = 0;
    let fatKittensOwned = 0;
    if (mainnetCollection.length > 0) {
      try {
        await Promise.all(
          mainnetCollection.map(async (item) => {
            let web;

            if (item?.selectedNetwork === config.networks.songbird) {
              web = new Web3(config.rpc.songbird);
            } else if (item?.selectedNetwork === config.networks.flare) {
              web = new Web3(config.rpc.flr);
            }
            const contract = new web.eth.Contract(mainnetABI, item?.contractAddress);
            if (web) {
              try {
                let nftData;
                try {
                  nftData = await contract.methods.balanceOf(address).call();
                }
                catch (error) {
                  // console.log(error, "ERROR in wallet of owner");
                }
                if (nftData) {
                  switch (item?.name) {
                    case 'The Fat Cats':
                      fatCatsOwned = nftData;
                      break;
                    case 'The Fat Leopards':
                      fatLeopardsOwned = nftData;
                      break;
                    case 'The Fat Tigers':
                      fatTigersOwned = nftData;
                      break;
                    case 'The Fat Kittens':
                      fatKittensOwned = nftData;
                      break;
                    default:
                      break;
                  }
                }
              } catch (nftError) {
                console.log(`Error fetching NFT data for ${item?.name}:`, nftError);
              }
            }
          })
        );



        // Determine membership level based on owned NFTs
        if (fatCatsOwned >= 1 && fatLeopardsOwned >= 1 && fatTigersOwned >= 1 && fatKittensOwned >= 1) {
          setUserMemberShip('Gold');  // GOLD: Must own 1x Fat Cats, Fat Leopards, Fat Tigers, and Fat Kittens
        } else if (fatLeopardsOwned >= 1 && fatTigersOwned >= 1 && fatKittensOwned >= 1) {
          setUserMemberShip('Silver');  // SILVER: Must own 1x Fat Leopards, Fat Tigers, and Fat Kittens
        } else if (fatTigersOwned >= 1 && fatKittensOwned >= 1) {
          setUserMemberShip('Bronze');  // BRONZE: Must own 1x Fat Tigers and Fat Kittens
        } else if (fatKittensOwned >= 1) {
          setUserMemberShip('Standard');  // STANDARD: Must own 1x Fat Kittens
        } else {
          setUserMemberShip(null);  // No membership
        }


      } catch (error) {
        console.log("Error in checking membership:", error);
      }
    }
  };



  //check membership qualification (gold, silver,bronze, standard) (local)

  const checkMembershipQualification = async () => {
    let fatCatsOwned = 0;
    let fatLeopardsOwned = 0;
    let fatTigersOwned = 0;
    let fatKittensOwned = 0;


    if (memberCollection.length > 0) {
      try {
        await Promise.all(
          memberCollection.map(async (item) => {
            let web;
            if (item?.data?.selectedNetwork === config.networks.songbird) {
              web = new Web3(config.rpc.songbird);
            } else {
              web = new Web3(config.rpc.flr);
            }

            const contract = new web.eth.Contract(mintABI, item?.data?.contractAddress);
            if (profAddress && web) {
              try {
                const nftData = await contract.methods.getTokenId(profAddress).call();
                // Check the collection name and count the NFTs owned
                if (nftData && nftData.length > 0) {
                  switch (item?.data?.name) {
                    case 'The Fat Cats':

                      fatCatsOwned = nftData.length;
                      break;
                    case 'The Fat Leopards':

                      fatLeopardsOwned = nftData.length;
                      break;
                    case 'The Fat Tigers':

                      fatTigersOwned = nftData.length;
                      break;
                    case 'The Fat Kittens':

                      fatKittensOwned = nftData.length;
                      break;
                    default:
                      break;
                  }
                }
              } catch (nftError) {
                console.error(`Error fetching NFT data for ${item?.data?.name}:`, nftError);
              }
            }
          })
        );

        // Determine membership level based on owned NFTs
        if (fatCatsOwned >= 1 && fatLeopardsOwned >= 1 && fatTigersOwned >= 1 && fatKittensOwned >= 1) {

          setUserMemberShip('Gold');  // GOLD: Must own 1x Fat Cats, Fat Leopards, Fat Tigers, and Fat Kittens
        } else if (fatLeopardsOwned >= 1 && fatTigersOwned >= 1 && fatKittensOwned >= 1) {
          setUserMemberShip('Silver');  // SILVER: Must own 1x Fat Leopards, Fat Tigers, and Fat Kittens
        } else if (fatTigersOwned >= 1 && fatKittensOwned >= 1) {
          setUserMemberShip('Bronze');  // BRONZE: Must own 1x Fat Tigers and Fat Kittens
        } else if (fatKittensOwned >= 1) {
          setUserMemberShip('Standard');  // STANDARD: Must own 1x Fat Kittens
        } else {
          setUserMemberShip(null);  // No membership
        }
      } catch (error) {
        console.log("Error in checkMembershipQualification:", error);
      }
    }
  };


  useEffect(() => {
    // if (currentEnv === "production") {
    //   checkMembershipQualificationMainnet();
    // } else if (currentEnv === "preview") {
    //   checkMembershipQualification();
    // } else if (currentEnv === "development") {
    //   checkMembershipQualification();
    // } else {
    //   checkMembershipQualification();
    //   console.log("Environment not recognized.");
    // }
    checkMembershipQualificationMainnet();
  }, [memberCollection, profAddress, currentEnv])


  const fetchDataForContract = async (contractAddress, selectedNetwork, profAddress) => {

    let web;
    if (selectedNetwork == config.networks.flare) {
      web = new Web3(config.rpc.flr);
    } else {
      web = new Web3(config.rpc.songbird);
    }
    let nftListDetails = [];

    if (web && address) {
      const contract = new web.eth.Contract(mintABI, contractAddress);

      const nftData = await contract.methods.getTokenId(address).call();
      let listedNfts = await getAllListNFTData(selectedNetwork, web);
      nftData?.map((item) => {
        let isSaleListed = false;
        let isOfferListed = false;
        let saleCountIndex = 0;  // Initialize saleCountIndex
        let offerCountIndex = 0; // Initialize offerCountIndex
        // Check for sale listings
        listedNfts[0]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isSaleListed = true;
            saleCountIndex = nft?.listCount;
            nftListDetails.push({

              ...nft,
              ...item,
              isListed: true,
              isSaleListed: true,
              saleCountIndex
            });
          }
        });

        // Check for offer listings
        listedNfts[1]?.forEach((nft) => {
          if (nft?.uriData === item?.uri) {
            isOfferListed = true;
            offerCountIndex = nft?.listCount;
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );

            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isOfferListed: true,
                offerCountIndex,
              };
            } else {
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isOfferListed: true,
                offerCountIndex,
              });
            }
          }
        });

        // Ensure both flags are set correctly for NFTs listed in both categories
        if (isSaleListed || isOfferListed) {
          const existingIndex = nftListDetails.findIndex(
            (nftDetail) => nftDetail.uri === item.uri
          );
          if (existingIndex !== -1) {
            nftListDetails[existingIndex] = {
              ...nftListDetails[existingIndex],
              isListed: true,
              isSaleListed: isSaleListed,
              isOfferListed: isOfferListed,
              saleCountIndex: isSaleListed
                ? saleCountIndex
                : nftListDetails[existingIndex].listCount,
              offerCountIndex: isOfferListed
                ? offerCountIndex
                : nftListDetails[existingIndex].listCount,
            };
          }
        }
      });

    }

    return nftListDetails;
  };

  // get nft meta data
  const getNftMetadata = async (tokens_uri, network) => {
    // Reset or initialize state variables
    setNfts([]);
    const nftMetadata = [];

    await Promise.all(
      tokens_uri.map(async (token) => {
        const uri = token.uri; // Extract URI from the current token
        const json = await fetchNFTMetadata(uri);
        // Check if the data object is already in nftMetadata array
        const existingIndex = nftMetadata.findIndex((item) => item.uri === uri);
        if (existingIndex === -1 && json.data && json.data) {
          // Push the data object into the nftMetadata array along with the original token
          nftMetadata.push({
            data: token,
            metadata: {
              uri: uri,
              data: json.data,
            },
          });
        }
      })
    );


    const filteredMetadata = nftMetadata.filter((item) => {
      return (item.data.newOwner)?.toLowerCase() === profAddress?.toLowerCase();
    });
    setCollectedNumber(filteredMetadata?.length);
    if (filteredMetadata?.length > 0) {
      return true
    }
    else {
      return false
    }
  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {

    let contractDataArray = [];

    for (const item of collections) {
      let data = await fetchDataForContract(
        item?.data.contractAddress,
        item?.data.selectedNetwork,
        profAddress
      );
      if (data?.length > 0) {
        contractDataArray.push(data);
      }
    }

    // Flatten the contractDataArray into a single array
    const flattenedTokens = contractDataArray.flat();

    // Pass the flattened array to getNftMetadata
    let res = await getNftMetadata(flattenedTokens);





  };

  useEffect(() => {
    if (collections?.length > 0 && profAddress) {
      fetchDataForAllContracts(profAddress);
    } else {

    }
  }, [collections, profAddress]);

  return (
    <NFTContext.Provider value={{
      nftList, updateNftList, change, isChange, showModal, updateModal, createdArtworks,
      updateCreated, artModal, udpateArtModal, reloadAfterTransfer, updateTransfer, search, updateSearch, updateAddress,
      reloadProfile, reload, setData, listedNfts, clearTags, updatedClearTags, tagsData, updateTagsData, sgbUsd, flrUsd, collectedNumber, updateProfAddress, userMembership, updateItemNumbers, itemNumberLike
    }}>
      {children}
    </NFTContext.Provider>
  );
};
